import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const BranchesList = () => {
  const [branches, setBranches] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [branchName, setBranchName] = useState('');
  const [branchCode, setBranchCode] = useState('');
  const [branchAcronym, setBranchAcronym] = useState('');
  const [toastMessage, setToastMessage] = useState(null);

  useEffect(() => {
    fetchBranches();
  }, []);

  const fetchBranches = () => {
    axios.get('http://localhost/avalun/backend/branches/list.php')
      .then(response => {
        console.log('Dados recebidos:', response.data); // Log dos dados recebidos
        setBranches(response.data);
      })
      .catch(error => {
        console.error('Erro ao buscar os dados:', error);
        setError('Erro ao buscar os dados');
      });
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('branch_name', branchName);
    formData.append('branch_code', branchCode);
    formData.append('branch_acronym', branchAcronym);

    fetch('http://localhost/avalun/backend/branches/add.php', {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        console.log('Unidade added successfully:', data);
        setToastMessage({
          type: 'success',
          text: 'Unidade adicionada com sucesso!'
        });
        fetchBranches(); // Fetch updated branches after adding a new branch
      } else {
        throw new Error(data.message);
      }
    })
    .catch(error => {
      console.error('Error adding branch:', error);
      setToastMessage({
        type: 'danger',
        text: `Erro ao adicionar unidade: ${error.message}. Por favor, tente novamente mais tarde.`
      });
    })
    .finally(() => {
      setTimeout(() => {
        setToastMessage(null);
      }, 3000); // Remove the toast message after 3 seconds
      setShowModal(false); // Close modal after the process
    });
  };

  const handleStatusChange = (branch_id, currentStatus) => {
    const newStatus = currentStatus === '1' ? '0' : '1';

    axios.post('http://localhost/avalun/backend/branches/update_status.php', {
      branch_id: branch_id,
      branch_status: newStatus
    })
    .then(response => {
      if (response.data.status === 'success') {
        setToastMessage({
          type: 'success',
          text: `Unidade ${newStatus === 1 ? 'ativada' : 'desativada'} com sucesso!`
        });
        fetchBranches(); // Atualiza a lista de unidades após a mudança de status
      } else {
        throw new Error(response.data.message);
      }
    })
    .catch(error => {
      console.error('Error updating status:', error);
      setToastMessage({
        type: 'danger',
        text: `Erro ao atualizar status: ${error.message}. Por favor, tente novamente mais tarde.`
      });
    })
    .finally(() => {
      setTimeout(() => {
        setToastMessage(null);
      }, 3000); // Remove the toast message after 3 seconds
    });
  };

  return (
    <>
    <div className="row">
      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div>
            <h1 className="mb-0">Unidades</h1>
            <small className="text-muted"></small>
          </div>
          <nav className="nav align-items-center gap-2">
            <button className="btn btn-default rounded-pill d-flex align-items-center" onClick={toggleModal}>
              <i className="bi bi-plus-circle fs-3"></i>
              <span className="ms-2">Cadastrar Unidade</span>
            </button>
          </nav>
        </div>
        <div className='painel__container'>
          <div>
            <table className='table'>
              <thead>
                <tr>
                  <th>Código:</th>
                  <th>Sigla:</th>
                  <th>Marca:</th>
                  <th>Status:</th>
                </tr>
              </thead>
              <tbody>
                {branches.length > 0 ? (
                  branches.map(branch => (
                    <tr key={branch.branch_id}>
                      <td>{branch.branch_code}</td>
                      <td>{branch.branch_acronym}</td>
                      <td>{branch.branch_name}</td>
                      <td>
                        <div className="form-check form-switch">
                          <input className="form-check-input" type="checkbox" id={`statusSwitch${branch.branch_id}`} 
                                 checked={branch.branch_status === '1'} 
                                 onChange={() => handleStatusChange(branch.branch_id, branch.branch_status)} />
                          <label className="form-check-label" htmlFor={`statusSwitch${branch.branch_id}`}>
                            {branch.branch_status === '1' ? 'Ativa' : 'Inativa'}
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">Nenhuma unidade encontrada.</td>
                  </tr>
                )}
              </tbody>
            </table>
            {error && <p className="text-danger">{error}</p>}
          </div>
        </div>
      </div>
    </div>

    {showModal && (
      <div className="modal" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h5 className="modal-title">Cadastrar Unidade</h5>
                <button type="button" className="btn-close" onClick={toggleModal}></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="branch_code" className="form-label">Código</label>
                  <input type="text" className="form-control" id="branch_code" value={branchCode} onChange={(e) => setBranchCode(e.target.value)} />
                </div>
                <div className="mb-3">
                  <label htmlFor="branch_acronym" className="form-label">Sigla</label>
                  <input type="text" className="form-control" id="branch_acronym" value={branchAcronym} onChange={(e) => setBranchAcronym(e.target.value)} />
                </div>
                <div className="mb-3">
                  <label htmlFor="branch_name" className="form-label">Nome</label>
                  <input type="text" className="form-control" id="branch_name" value={branchName} onChange={(e) => setBranchName(e.target.value)} />
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={toggleModal}>Cancelar</button>
                <button type="submit" className="btn btn-primary">Cadastrar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )}

    {toastMessage && (
      <div className="position-fixed top-50 start-50 translate-middle" style={{ zIndex: 1055 }}>
        <div className={`toast show align-items-center text-bg-${toastMessage.type} border-0`} role="alert" aria-live="assertive" aria-atomic="true">
          <div className="d-flex">
            <div className="toast-body">
              <i className={`bi ${toastMessage.type === 'success' ? 'bi-check-circle' : 'bi-exclamation-circle'} me-2`}></i>
              {toastMessage.text}
            </div>
            <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" onClick={() => setToastMessage(null)}></button>
          </div>
        </div>
      </div>
    )}
    </>
  );
};

export default BranchesList;