import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import PanelNavBar from '../../../components/PanelNavBar';



const Reports = () => {
  return (
    <>
     <li><Link to="frequencia">Vendas</Link></li>
     <li><Link to="frequencia">Funcionários</Link></li>
     <Outlet />





     
      {/* <div className='d-flex gap-3'>
        <div style={{ width: '15%', top: '4rem', backgroundColor: '#8d2438' }} className='sticky-top h-100 px-2 py-5 rounded'>
          <PanelNavBar />
        </div>

        <div style={{ width: '85%' }}>
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <div>
                  <h1 className="mb-0">Relatorios</h1>
                  <small className="text-muted">Relatorios do Sistema:</small>
                </div>
              </div>
              <div className='painel__container'>
                <div>
                  <h2>Turmas</h2>
                  <ul>
                    <li><Link to="./">Desistencia</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>


  );
};

export default Reports;

