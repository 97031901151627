import React, { useEffect } from 'react';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';

const ParticipantsTable = ({ participants }) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
        $('#participantsTable').DataTable({
            responsive: true
        });
    }
  }, []);

  return (
    <table id="participantsTable" className="table table-striped table-bordered nowrap">
      <thead>
        <tr>
          <th>Foto</th>
          <th>Nome</th>
          <th>Telefone</th>
          <th>Código</th>
          <th>Papel</th>
          <th>Último Acesso</th>
        </tr>
      </thead>
      <tbody>
        {participants.map(participant => (
          <tr key={participant.user_id}>
            <td>
              <img src={participant.user_photo} alt={participant.user_name} className="rounded-circle" width="40" />
            </td>
            <td>{participant.user_name}</td>
            <td>{participant.user_phone}</td>
            <td>{participant.user_code}</td>
            <td>{participant.role}</td>
            <td>{participant.last_access ? new Date(participant.last_access).toLocaleString() : 'Nunca'}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ParticipantsTable;