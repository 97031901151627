import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './ModPage.css';

const ModPage = () => {
  const { type, id } = useParams();
  const [contentDetails, setContentDetails] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('Tipo:', type);
    console.log('ID:', id);
    fetch(`http://localhost/avalun/backend/get_mod.php?type=${type}&id=${id}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro na resposta da rede');
        }
        return response.json();
      })
      .then(data => {
        if (data.message) {
          setError(data.message);
        } else {
          setContentDetails(data);
        }
      })
      .catch(error => {
        setError('Erro ao buscar os dados');
        console.error('Erro ao buscar os dados:', error);
      });
  }, [type, id]);

  if (error) {
    return (
      <div className="container mt-5">
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      </div>
    );
  }

  if (!contentDetails) {
    return (
      <div className="container mt-5">
        <div>Carregando...</div>
      </div>
    );
  }

  const renderContent = () => {
    switch (type) {
      case 'content':
        return <div dangerouslySetInnerHTML={{ __html: contentDetails.content_text }} />;
      case 'link':
        return (
          <a href={contentDetails.link_url} target="_blank" rel="noopener noreferrer" className="btn btn-primary">
            {contentDetails.link_title}
          </a>
        );
      case 'quiz':
        return (
          <div>
            <h2>{contentDetails.quiz_title}</h2>
            <p>{contentDetails.quiz_description}</p>
            {/* Lógica adicional para exibir perguntas do quiz */}
          </div>
        );
      case 'activity':
        return (
          <div>
            <h2>{contentDetails.activity_title}</h2>
            <p>{contentDetails.activity_description}</p>
            {/* Lógica adicional para envio de tarefas */}
          </div>
        );
      case 'forum':
        return (
          <div>
            <h2>{contentDetails.forum_title}</h2>
            <p>{contentDetails.forum_description}</p>
            {/* Lógica adicional para exibir posts do fórum */}
          </div>
        );
      default:
        return <div>Tipo desconhecido.</div>;
    }
  };

  return (
    <div className="container mt-5">
      <div className="card border-0 rounded-4 shadow-sm">
        <div className="card-header rounded-top-4 bg-primary text-white">
          <h1 className="card-title">{contentDetails.title}</h1>
        </div>
        <div className="card-body">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default ModPage;