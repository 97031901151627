import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AccountsForm = ({ onFormChange }) => {
  const [roles, setRoles] = useState([]);
  const [form, setForm] = useState({
    name: '',
    code: '',
    unity: '',
    city: '',
    state: '',
    phone: '',
    email: '',
    role: '',
    image: null,
  });

  useEffect(() => {
    axios.get('http://localhost/avalun/backend/acconts/accountsRole.php')
      .then(res => setRoles(res.data))
      .catch(error => console.error('Error fetching roles:', error));
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm(prevForm => {
      const updatedForm = { ...prevForm, [name]: type === 'checkbox' ? checked : value };
      onFormChange(updatedForm);
      return updatedForm;
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setForm(prevForm => {
      const updatedForm = { ...prevForm, image: file };
      onFormChange(updatedForm);
      return updatedForm;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in form) {
      formData.append(key, form[key]);
    }

    axios.post('http://localhost/avalun/backend/acconts/registerUser.php', formData)
      .then(res => console.log(res.data))
      .catch(error => console.error('Error submitting form:', error));
  };

  return (
    <form onSubmit={handleSubmit} className="container">
      <div className="mb-3">
        <label htmlFor="code" className="form-label">Código do Usuário</label>
        <input type="number" className="form-control" id="code" name="code" value={form.code} onChange={handleChange} required />
      </div>

      <div className="mb-3">
        <label htmlFor="name" className="form-label">Nome do Usuário</label>
        <input type="text" className="form-control" id="name" name="name" value={form.name} onChange={handleChange} required />
      </div>

      <div className="mb-3">
        <label htmlFor="unidade" className="form-label">Unidade</label>
        <input type="number" className="form-control" id="unidade" name="unity" value={form.unity} onChange={handleChange} required />
      </div>


      <div className="mb-3">
        <label htmlFor="email" className="form-label">Email do Usuário</label>
        <input type="text" className="form-control" id="email" name="email" value={form.email} onChange={handleChange} required />
      </div>

      <div className="mb-3">
        <label htmlFor="telefone" className="form-label">Telefone do Usuário</label>
        <input type="phone" className="form-control" id="phone" name="phone" value={form.phone} onChange={handleChange} required />
      </div>

      <div className="mb-3">
        <label htmlFor="estado" className="form-label">Estado</label>
        <input type="text" className="form-control" id="state" name="state" value={form.state} onChange={handleChange} required />
      </div>

      <div className="mb-3">
        <label htmlFor="cidade" className="form-label">Cidade</label>
        <input type="text" className="form-control" id="city" name="city" value={form.city} onChange={handleChange} required />
      </div>



      <div className="mb-3">
        <label htmlFor="role" className="form-label">Cargo do Usuario</label>
        <select className="form-select" id="role" name="role" value={form.role} onChange={handleChange} required>
          <option value="">Selecione um Cargo</option>
          {roles.map(role => (
            <option key={role.id} value={role.name}>{role.name}</option>
          ))}
        </select>
      </div>

      <div className="mb-3">
        <label htmlFor="image" className="form-label">Imagem do Usuário</label>
        <input type="file" className="form-control" id="image" name="image" onChange={handleFileChange} />
      </div>

      <button type="submit" className="btn btn-primary">Cadastrar Usuário</button>
    </form>
  );
};

export default AccountsForm;