import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const PanelNavBar = () => {

    const location = useLocation();

    const getNavLinkClass = (path) => {
      return location.pathname === path ? 'nav-link active-tab' : 'nav-link';
    };

    return (
        <div className="offcanvas-md offcanvas-end" tabindex="-1" id="sidebarPanel" aria-labelledby="sidebarPanelLabel">
            <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="sidebarPanelLabel"></h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarPanel" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
            <ul className="nav flex-column">
                <li className="nav-item">
                <Link className={`nav-link d-flex align-items-center gap-2 ${getNavLinkClass('/painel')}`} to="/painel">
                    <i className="bi bi-house-fill"></i>
                    Painel
                </Link>
                </li>
                <li className="nav-item">
                <Link className={`nav-link d-flex align-items-center gap-2 ${getNavLinkClass('/painel/contas')}`}
                to="/painel/contas">
                <i className="bi bi-people"></i>
                    Contas
                </Link>
                </li>
                <li className="nav-item">
                <Link className={`nav-link d-flex align-items-center gap-2 ${getNavLinkClass('/painel/cursos')}`}
                to="/painel/cursos">
                    <i className="bi bi-book"></i>
                    Cursos
                </Link>
                </li>
                <li className="nav-item">
                <Link className={`nav-link d-flex align-items-center gap-2 ${getNavLinkClass('/painel/unidades')}`}
                to="/painel/unidades">
                    <i className="bi bi-buildings"></i>
                    Unidades
                </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link d-flex align-items-center gap-2" href="#">
                    <i className="bi bi-graph-up"></i>
                    Reports
                </a>
                </li>
                <li className="nav-item">
                <a className="nav-link d-flex align-items-center gap-2" href="#">
                    <i className="bi bi-puzzle"></i>
                    Integrations
                </a>
                </li>
            </ul>

            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-body-secondary text-uppercase">
                <span>Saved reports</span>
                <a className="link-secondary" href="#" aria-label="Add a new report">
                <i className="bi bi-plus-circle"></i>
                </a>
            </h6>
            <ul className="nav flex-column mb-auto">
                <li className="nav-item">
                <a className="nav-link d-flex align-items-center gap-2" href="#">
                    <i className="bi bi-file-earmark-text"></i>
                    Current month
                </a>
                </li>
                <li className="nav-item">
                <a className="nav-link d-flex align-items-center gap-2" href="#">
                    <i className="bi bi-file-earmark-text"></i>
                    Last quarter
                </a>
                </li>
                <li className="nav-item">
                <a className="nav-link d-flex align-items-center gap-2" href="#">
                    <i className="bi bi-file-earmark-text"></i>
                    Social engagement
                </a>
                </li>
                <li className="nav-item">
                <a className="nav-link d-flex align-items-center gap-2" href="#">
                    <i className="bi bi-file-earmark-text"></i>
                    Year-end sale
                </a>
                </li>
            </ul>

            <hr className="my-3" />

            <ul className="nav flex-column mb-auto">
                <li className="nav-item">
                <a className="nav-link d-flex align-items-center gap-2" href="#">
                    <i className="bi bi-gear-wide-connected"></i>
                    Settings
                </a>
                </li>
                <li className="nav-item">
                <a className="nav-link d-flex align-items-center gap-2" href="#">
                    <i className="bi bi-door-closed"></i>
                    Sign out
                </a>
                </li>
            </ul>
            </div>
        </div>
    )
};

export default PanelNavBar;
