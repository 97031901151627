import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const UserDetails = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(`http://localhost/avalun/backend/acconts/listUsersDetails.php?id=${id}`)
      .then(response => {
        setUser(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Erro ao buscar os detalhes do usuário:', error);
        setError(error.message);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <div className="container mt-5"><p>Carregando...</p></div>;
  }

  if (error) {
    return <div className="container mt-5"><p>Erro: {error}</p></div>;
  }

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Detalhes do Usuário</h1>
      {user && (
        <div className="card border-0 shadow-sm rounded-4">
          <div className="card-body">
            <h5 className="card-title">{user.user_name}</h5>
            <h6 className="card-subtitle mb-2 text-muted">{user.user_email}</h6>
            <p className="card-text"><strong>Código:</strong> {user.user_code}</p>
            <p className="card-text"><strong>Última Atividade:</strong> {user.user_last_activity || 'N/A'}</p>
            <p className="card-text"><strong>Unidade:</strong> {user.user_unity}</p>
            <p className="card-text"><strong>Cidade/Estado:</strong> {user.user_stateCity || 'N/A'}</p>
            <p className="card-text"><strong>Telefone:</strong> {user.user_phone || 'N/A'}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetails;
