import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CourseForm = ({ onFormChange }) => {
  const [categories, setCategories] = useState([]);
  const [modalities, setModalities] = useState([]);

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  const getDateAfter60Days = () => {
    const today = new Date();
    const futureDate = new Date(today.setDate(today.getDate() + 60));
    return futureDate.toISOString().split('T')[0];
  };

  const [form, setForm] = useState({
    name: '',
    code: '',
    category: '',
    visibility: '',
    startDate: getCurrentDate(),
    endDate: getDateAfter60Days(),
    hasEndDate: false,
    modality: '',
    summary: '',
    image: null,
    format: '',
    sections: 0
  });

  useEffect(() => {
    // Fetch categories and modalities from the backend
    axios.get('http://localhost/avalun/backend/getCategories.php').then(res => setCategories(res.data));
    axios.get('http://localhost/avalun/backend/getModalities.php').then(res => setModalities(res.data));
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm(prevForm => {
      const updatedForm = { ...prevForm, [name]: type === 'checkbox' ? checked : value };
      onFormChange(updatedForm);
      return updatedForm;
    });
  };

  const handleFileChange = (e) => {
    setForm(prevForm => {
      const updatedForm = { ...prevForm, image: e.target.files[0] };
      onFormChange(updatedForm);
      return updatedForm;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in form) {
      formData.append(key, form[key]);
    }
    axios.post('http://localhost/avalun/backend/saveCourse.php', formData).then(res => {
      console.log(res.data);
    });
  };

  return (
    <form onSubmit={handleSubmit} autoComplete="off" 
    className="container">
      <div className="mb-3">
        <label htmlFor="name" className="form-label">Nome do curso</label>
        <input type="text" autoComplete="off" className="form-control" id="name" name="name" value={form.name} onChange={handleChange} required />
      </div>
      <div className="mb-3">
        <label htmlFor="code" className="form-label">Código do curso</label>
        <input type="text" autoComplete="off" className="form-control" id="code" name="code" value={form.code} onChange={handleChange} required />
      </div>
      <div className="mb-3">
        <label htmlFor="category" className="form-label">Categoria do curso</label>
        <select className="form-select" id="category" name="category" value={form.category} onChange={handleChange} required>
          <option value="">Selecione uma categoria</option>
          {categories.map(category => (
            <option key={category.id} value={category.id}>{category.name}</option>
          ))}
        </select>
      </div>
      <div className="mb-3">
        <label className="form-label">Visibilidade do curso</label>
        <div className="form-check">
          <input className="form-check-input" type="radio" id="show" name="visibility" value="show" checked={form.visibility === 'show'} onChange={handleChange} />
          <label className="form-check-label" htmlFor="show">Mostrar</label>
        </div>
        <div className="form-check">
          <input className="form-check-input" type="radio" id="hide" name="visibility" value="hide" checked={form.visibility === 'hide'} onChange={handleChange} />
          <label className="form-check-label" htmlFor="hide">Ocultar</label>
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="startDate" className="form-label">Data de início do curso</label>
        <input type="date" className="form-control" id="startDate" name="startDate" value={form.startDate} onChange={handleChange} required />
      </div>
      <div className="mb-3 form-check">
        <input type="checkbox" className="form-check-input" id="hasEndDate" name="hasEndDate" checked={form.hasEndDate} onChange={handleChange} />
        <label className="form-check-label" htmlFor="hasEndDate">Data de término do curso</label>
      </div>
      {form.hasEndDate && (
        <div className="mb-3">
          <label htmlFor="endDate" className="form-label">Data de término do curso</label>
          <input type="date" className="form-control" id="endDate" name="endDate" value={form.endDate} onChange={handleChange} />
        </div>
      )}
      <div className="mb-3">
        <label htmlFor="modality" className="form-label">Modalidade do curso</label>
        <select className="form-select" id="modality" name="modality" value={form.modality} onChange={handleChange} required>
          <option value="">Selecione uma modalidade</option>
          {modalities.map(modality => (
            <option key={modality.id} value={modality.id}>{modality.name}</option>
          ))}
        </select>
      </div>
      <div className="mb-3">
        <label htmlFor="summary" className="form-label">Sumário do curso</label>
        <textarea className="form-control" id="summary" name="summary" rows="3" value={form.summary} onChange={handleChange}></textarea>
      </div>
      <div className="mb-3">
        <label htmlFor="image" className="form-label">Imagem do curso</label>
        <input type="file" className="form-control" id="image" name="image" onChange={handleFileChange} />
      </div>
      <div className="mb-3">
        <label htmlFor="format" className="form-label">Formato do curso</label>
        <select className="form-select" id="format" name="format" value={form.format} onChange={handleChange} required>
          <option value="sections">Seções</option>
          <option value="single">Atividade única</option>
        </select>
      </div>
      {form.format === 'sections' && (
        <div className="mb-3">
          <label htmlFor="sections" className="form-label">Número de seções</label>
          <input type="number" className="form-control" id="sections" name="sections" value={form.sections} onChange={handleChange} min="0" />
        </div>
      )}
      <button type="submit" className="btn btn-primary">Salvar Curso</button>
    </form>
  );
};

export default CourseForm;