import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ParticipantsTable from '../components/ParticipantsTable';
import ParticipantsAdd from '../components/ParticipantsAdd';

const ParticipantsPage = () => {
  const { classID } = useParams(); // Pega o ID da turma da URL
  const [participants, setParticipants] = useState([]);
  const [error, setError] = useState(null);

  const fetchParticipants = () => {
    fetch(`http://localhost/avalun/backend/participants.php?class_id=${classID}`)
      .then(response => response.json())
      .then(data => {
        setParticipants(data);
      })
      .catch(error => setError('Erro ao buscar os participantes'));
  };

  useEffect(() => {
    fetchParticipants();
  }, [classID]);

  return (
    <div className="container mt-5">
      <h1>Participantes da Turma</h1>
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="d-flex justify-content-between mb-3">
        <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addParticipantModal">
          Adicionar Participante
        </button>
      </div>
      <ParticipantsTable participants={participants} />
      <ParticipantsAdd classID={classID} onParticipantAdded={fetchParticipants} />
    </div>
  );
};

export default ParticipantsPage;