import { useEffect } from 'react';
import axios from 'axios';

const UserActivityUpdater = () => {
  useEffect(() => {
    const updateActivity = () => {
      const userId = localStorage.getItem('user_id');
      if (userId) {
        axios.get(`http://localhost/avalun/backend/get_user.php?update&user_id=${userId}`)
          .then(response => {
            if (response.data.status !== 'success') {
              console.error('Erro ao atualizar a atividade do usuário:', response.data.message);
            }
          })
          .catch(error => {
            console.error('Erro ao se comunicar com o servidor:', error);
          });
      }
    };

    updateActivity();
    const intervalId = setInterval(updateActivity, 1 * 60 * 1000); // Atualizar a cada minuto

    return () => clearInterval(intervalId);
  }, []);

  return null;
};

export default UserActivityUpdater;