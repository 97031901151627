import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const NoticesModal = ({ show, handleClose }) => {
  const { classID } = useParams(); // Pega o ID da turma da URL
  const [notices, setNotices] = useState([]);
  const [currentNoticeIndex, setCurrentNoticeIndex] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`http://localhost/avalun/backend/courses/notices.php?action=get_notices&class_id=${classID}`)
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setNotices(data);
        } else {
          setError('Nenhum aviso encontrado ou dados inválidos.');
        }
      })
      .catch(error => setError('Erro ao buscar os avisos'));
  }, [classID]);

  const handleNext = () => {
    setCurrentNoticeIndex((prevIndex) => (prevIndex + 1) % notices.length);
  };

  const handlePrev = () => {
    setCurrentNoticeIndex((prevIndex) => (prevIndex - 1 + notices.length) % notices.length);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Avisos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error ? (
          <div className="alert alert-danger">{error}</div>
        ) : (
          notices.length > 0 && (
            <>
              <h5>{notices[currentNoticeIndex].notice_title}</h5>
              <p>{notices[currentNoticeIndex].notice_content}</p>
            </>
          )
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handlePrev} disabled={notices.length <= 1}>
          Anterior
        </Button>
        <Button variant="primary" onClick={handleNext} disabled={notices.length <= 1}>
          Próximo
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NoticesModal;