import React from 'react';

const AccountsInfos = ({ formData }) => {
  const renderField = (label, value) => {
    if (value) {
      return (
        <>
          <dt>{label}</dt>
          <dd>{value}</dd>
        </>
      );
    }
    return null;
  };

  return (
    <div id="CourseInfos" className="">
      <dl>
      {formData.image && (
          <>
            <dt>Imagem do Usuário:</dt>
            <dd><img src={URL.createObjectURL(formData.image)} alt="Imagem do Usuário" style={{ maxWidth: '100px' }} /></dd>
          </>
        )}
        {renderField('Código do Usuário:', formData.code)}
        {renderField('Nome do Usuário:', formData.name)}
        {renderField('Unidade:', formData.unity)}
        {renderField('Email:', formData.email)}
        {renderField('Telefone:', formData.phone)}
        {renderField('Estado:', formData.state)}
        {renderField('Cidade:', formData.city)}
        {renderField('Cargo:', formData.Role)}
       
      </dl>
    </div>
  );
};

export default AccountsInfos;


