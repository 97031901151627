import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const AttendancePage = () => {
  const { classID } = useParams(); // Pega o ID da turma da URL
  const [users, setUsers] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`http://localhost/avalun/backend/courses/attendance.php?action=get_users&class_id=${classID}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro ao buscar os estudantes');
        }
        return response.json();
      })
      .then(data => setUsers(data))
      .catch(error => setError('Erro ao buscar os estudantes'));

    fetch(`http://localhost/avalun/backend/courses/attendance.php?action=get_lessons&class_id=${classID}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro ao buscar as aulas');
        }
        return response.json();
      })
      .then(data => setLessons(data))
      .catch(error => setError('Erro ao buscar as aulas'));
  }, [classID]);

  const handleAttendanceSubmit = (userId) => {
    const status = document.getElementById(`status_${userId}`).value;
    const notes = document.getElementById(`notes_${userId}`).value;

    const formData = new FormData();
    formData.append('attendance_user', userId);
    formData.append('attendance_class', classID);
    formData.append('attendance_lesson', selectedLesson);
    formData.append('attendance_status', status);
    formData.append('attendance_notes', notes);

    fetch('http://localhost/avalun/backend/courses/attendance.php?action=register_attendance', {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => alert(data.message))
      .catch(error => setError('Erro ao registrar a presença'));
  };

  return (
    <div className="container mt-5">
      {error && <div className="alert alert-danger">{error}</div>}

      <div className="mb-4">
        <h3>Selecione a Aula:</h3>
        <select className="form-select" onChange={e => setSelectedLesson(e.target.value)}>
          <option value="">Selecione uma aula</option>
          {lessons.map(lesson => (
            <option key={lesson.lesson_id} value={lesson.lesson_id}>
              {lesson.lesson_available_from} - {lesson.lesson_available_until}
            </option>
          ))}
        </select>
      </div>

      {selectedLesson && (
        <div className="row row-cols-1 row-cols-md-2 g-4">
          {users.map(user => (
            <div className="col" key={user.user_id}>
              <div className="card h-100">
                <img src={user.user_photo || 'default_image.jpg'} className="card-img-top" alt={user.user_name} />
                <div className="card-body">
                  <h5 className="card-title">{user.user_name}</h5>
                  <p className="card-text">
                    Código: {user.user_code}<br />
                    Telefone: {user.user_phone}
                  </p>
                  <div className="form-group">
                    <label htmlFor={`status_${user.user_id}`}>Status de Presença</label>
                    <select className="form-select" id={`status_${user.user_id}`} name={`status_${user.user_id}`}>
                      <option value="Presente (Presencial)">Presente (Presencial)</option>
                      <option value="Presente (Remoto)">Presente (Remoto)</option>
                      <option value="Ausente">Ausente</option>
                    </select>
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor={`notes_${user.user_id}`}>Observações/ocorrências</label>
                    <textarea className="form-control" id={`notes_${user.user_id}`} name={`notes_${user.user_id}`}></textarea>
                  </div>
                  <button onClick={() => handleAttendanceSubmit(user.user_id)} className="btn btn-primary mt-3">Registrar Presença</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AttendancePage;
