import React from 'react';

const ProfileEdit = ({ user }) => {
  return (
    <div>
      <h1>Editar Perfil</h1>
      <form>
        <div className="mb-3">
          <label htmlFor="userName" className="form-label">Nome</label>
          <input type="text" className="form-control" id="userName" defaultValue={user.user_name} />
        </div>
        <div className="mb-3">
          <label htmlFor="userEmail" className="form-label">Email</label>
          <input type="email" className="form-control" id="userEmail" defaultValue={user.user_email} />
        </div>
        <button type="submit" className="btn btn-primary">Salvar</button>
      </form>
    </div>
  );
};

export default ProfileEdit;