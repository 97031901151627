import React, { useState } from 'react';
import axios from 'axios';

const PermissionsRegister = () => {
    const [name, setName] = useState('');
    const [response, setResponse] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', name);

        axios.post('http://localhost/avalun/backend/acconts/registerRole.php', formData)
            .then(response => {
                setResponse(response.data.message || 'Success');
            })
            .catch(error => {
                setResponse(error.response?.data?.error || 'Error occurred');
            });
    };

    return (
        <div className="container mt-5">
        <h1>Cadastrar Cargo:</h1>
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label htmlFor="name" className="form-label">Cargo:</label>
                <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
        </form>
        {response && <div>{response}</div>}
    </div>
    );
};

export default PermissionsRegister;
