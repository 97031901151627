import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import 'moment/locale/pt-br';

moment.updateLocale('pt-br', {
  relativeTime : {
    future: "em %s",
    past:   "há %s",
    s  : 'alguns segundos',
    ss : '%d segundos',
    m:  "um minuto",
    mm: "%d minutos",
    h:  "uma hora",
    hh: "%d horas",
    d:  "um dia",
    dd: "%d dias",
    w:  "uma semana",
    ww: "%d semanas",
    M:  "um mês",
    MM: "%d meses",
    y:  "um ano",
    yy: "%d anos"
  }
});

const Post = ({ post }) => {
  const [likes, setLikes] = useState(post.post_likes);
  const [shares, setShares] = useState(post.post_shares);
  const [views, setViews] = useState(post.post_views);
  const [comment, setComment] = useState('');
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [showShareBox, setShowShareBox] = useState(false);
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleInteraction = (action) => {
    // Simular lógica para permitir curtir apenas uma vez
    if (action === 'like' && !post.liked) {
      fetch('http://localhost/avalun/backend/interactions.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ post_id: post.post_id, action: action }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setLikes(data.newCount);
            // Marcar o post como curtido para evitar múltiplas curtidas
            post.liked = true;
          }
        });
    }

    // Implementar lógica para compartilhar nas redes sociais
    if (action === 'share') {
      // Mostrar a div flutuante para compartilhar nas redes sociais
      setShowShareBox(true);
    }

    // Outras interações como 'view' podem ser tratadas aqui
  };

  const handleComment = () => {
    // Enviar o comentário para o backend
    // Implementar lógica para enviar o comentário
    console.log('Comentário enviado:', comment);
    // Resetar o estado do comentário
    setComment('');
  };

  return (
    <div className="card mb-4 shadow-sm border-0 rounded-4">
      <div className="card-body">
        <h5 className="card-title">{post.post_title}</h5>
        <h6 className="card-subtitle mb-2 text-muted">
          <small className="text-mute">{moment(post.post_added).fromNow()} - <i className="bi bi-person-circle"></i> {post.post_author}</small> 
        </h6>
        <p className="card-text">{post.post_content}</p>
        <div className="d-flex justify-content-start">
            <a
              href="!#"
              className="card-link"
              onClick={(e) => {
                e.preventDefault();
                handleInteraction('like');
              }}
            >
              <i className="bi bi-heart"></i> {likes}
            </a>
            <a
              href="!#"
              title={t("share")}
              className="card-link"
              onClick={(e) => {
                e.preventDefault();
                handleInteraction('share');
              }}
            >
              <i className="bi bi-share"></i> {shares}
            </a>
            <a
              href="!#"
              className="card-link"
              onClick={(e) => {
                e.preventDefault();
                setShowCommentBox(true);
              }}
            >
              <i className="bi bi-chat"></i> {t('comments')}
            </a>
        </div>
        {showShareBox && (
          <div className="mt-3 d-flex align-items-center">
            <button className="btn btn-primary mr-2">Facebook</button>
            <button className="btn btn-info mr-2">Twitter</button>
            <button className="btn btn-success mr-2">WhatsApp</button>
            <button className="btn btn-sm btn-outline-danger ms-auto" onClick={() => setShowShareBox(false)}>
              <i className="bi bi-x-lg"></i>
            </button>
          </div>
        )}
        {showCommentBox && (
          <div className="mt-3">
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="form-control"
              placeholder="Write your comment here..."
            ></textarea>
            <div className="d-flex justify-content-between align-items-center">
              <button className="btn btn-primary mt-2" onClick={handleComment}>
                {t('submit')}
              </button>
              <button className="btn btn-sm btn-outline-danger" onClick={() => setShowCommentBox(false)}>
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Post;