import React, { useEffect, useState } from 'react';
import Post from '../components/Post';
import UserStatus from '../components/UserStatus';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import RichTextEditor from '../components/RichTextEditor';

const HomePage = ({ startChat }) => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [postTitle, setPostTitle] = useState('');
  const [postContent, setPostContent] = useState('');
  const [postPinned, setPostPinned] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [users, setUsers] = useState([]);
  const [greeting, setGreeting] = useState('Bem-vindo');
  const [userName, setUserName] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    fetchPosts();
      const fetchUserName = async () => {
          const userId = localStorage.getItem('user_id');
          if (userId) {
              try {
                  const response = await fetch(`http://localhost/avalun/backend/get_user.php?user_id=${userId}`);
                  const data = await response.json();
                  if (data.status === 'success') {
                      setUserName(data.user_name);
                  } else {
                      console.error(data.message);
                  }
              } catch (error) {
                  console.error('Erro ao buscar informações do usuário:', error);
              }
          }
      };

      fetchUserName();

      // Definir saudação baseada na hora do dia
      const hour = new Date().getHours();
      if (hour < 12) setGreeting(t('good-morning'));
      else if (hour < 18) setGreeting('Boa tarde');
      else setGreeting('Boa noite');
  }, []);

  useEffect(() => {
    axios.get('http://localhost/avalun/backend/messages/actions.php?users')
      .then(response => setUsers(response.data))
      .catch(error => console.error('Error fetching users:', error));
  }, []);

  const fetchPosts = () => {
    fetch('http://localhost/avalun/backend/get_posts.php')
      .then((response) => {
        console.log('Response status:', response.status);
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        console.log('Data received:', data);
        setPosts(data);
      })
      .catch((error) => {
        console.error('Error fetching posts:', error);
        setError(error.toString());
      });
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('post_title', postTitle);
    formData.append('post_content', postContent);
    formData.append('post_pinned', postPinned ? 0 : 1);

    fetch('http://localhost/avalun/backend/add_post.php', {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .then(data => {
      console.log('Post added successfully:', data);
      setConfirmationMessage({
        type: 'success',
        text: 'Post adicionado com sucesso!'
      });
      setTimeout(() => {
        setConfirmationMessage('');
      }, 3000); // Remove the confirmation message after 3 seconds
      fetchPosts(); // Fetch updated posts after adding a new post
      setShowModal(false); // Close modal after successful post
    })
    .catch(error => {
      console.error('Error adding post:', error);
      setError(error.toString());
      setConfirmationMessage({
        type: 'danger',
        text: 'Erro ao adicionar post. Por favor, tente novamente mais tarde.'
      });
    });
  };

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <div>
                <h1 className="mb-0">{greeting}, {userName}!</h1>
                <small className="text-muted">Leia os últimos posts da sua unidade:</small>
              </div>
              <button className="btn btn-default rounded-pill d-flex align-items-center" onClick={toggleModal}>
                <i className="bi bi-plus-circle fs-3"></i>
                <span className="ms-2">Publicar</span>
              </button>
            </div>
            {confirmationMessage && (
              <div className={`alert alert-${confirmationMessage.type} alert-dismissible fade show`} role="alert">
                <strong>{confirmationMessage.type === 'success' ? 'Sucesso!' : 'Erro!'}</strong> {confirmationMessage.text}
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setConfirmationMessage('')}></button>
              </div>
            )}
          </div>
          <div className="col-md-8">
            {error && <p>Error: {error}</p>}
            {posts.map((post) => (
              <Post key={post.post_id} post={post} />
            ))}
            <nav aria-label="Page navigation example">
              <ul class="pagination justify-content-center">
                <li class="page-item">
                  <a class="page-link" href="!#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li class="page-item"><a class="page-link" href="!#">1</a></li>
                <li class="page-item"><a class="page-link" href="!#">2</a></li>
                <li class="page-item"><a class="page-link" href="!#">3</a></li>
                <li class="page-item">
                  <a class="page-link" href="!#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="col-md-4">
            <div className="stiky-top">
              <div className="card border-0 rounded-4 shadow-sm" style={{ maxHeight: '80vh' }}>
                <div className="card-header rounded-top-4">
                  <h5 className="card-title mb-0">Usuários Online</h5>
                </div>
                <div className="card-body overflow-auto" style={{ maxHeight: '60vh' }}>
                  <ul className="list-group list-group-flush">
                    {users.map(user => (
                      <li key={user.user_id} className="list-group-item d-flex justify-content-between align-items-center">
                        <UserStatus userId={user.user_id} />
                        <div className="ms-2">{user.user_name}</div>
                        <button className="btn btn-default rounded-pill ms-auto btn-sm" onClick={() => startChat(user.user_id)}><i className="bi bi-chat-dots"></i></button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal" style={{ display: 'block' }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <form onSubmit={handleSubmit}>
                <div className="modal-header">
                  <h5 className="modal-title">Novo Post</h5>
                  <button type="button" className="btn-close" onClick={toggleModal}></button>
                </div>
                <div className="modal-body">
                  <div className="mb-3">
                    <label htmlFor="post_title" className="form-label">Título</label>
                    <input type="text" className="form-control" id="post_title" value={postTitle} onChange={(e) => setPostTitle(e.target.value)} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="post_content" className="form-label">Conteúdo</label>
                    <RichTextEditor id="post_content" name="post_content" placeholder="Comece a digitar..." />
                  </div>
                  <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="post_pinned" checked={postPinned} onChange={(e) => setPostPinned(e.target.checked)} />
                    <label className="form-check-label" htmlFor="post_pinned">Fixar Post</label>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={toggleModal}>Cancelar</button>
                  <button type="submit" className="btn btn-primary">Publicar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

    </>
  );
};

export default HomePage;