import axios from 'axios';

const getUserIP = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json');
    return response.data.ip;
  } catch (error) {
    console.error('Error getting IP address:', error);
    return 'IP desconhecido';
  }
};

const getBrowserInfo = () => {
  return navigator.userAgent;
};

const logEvent = async (log_user, log_action) => {
  const log_ip = await getUserIP();
  const log_browser = getBrowserInfo();
  const logData = {
    log_user,
    log_action,
    log_ip,
    log_browser
  };

  axios.post('http://localhost/avalun/backend/logs/create.php', logData)
    .then(response => {
      console.log('Log registrado com sucesso:', response.data);
    })
    .catch(error => {
      console.error('Erro ao registrar log:', error);
    });
};

export default logEvent;