import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import './Lesson.css';

const LessonPage = () => {
  const userID = localStorage.getItem('user_id');
  const { lessonID } = useParams();
  const [lessonDetails, setLessonDetails] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleToggleCompleted = async (currentStatus) => {
    try {
      const response = await fetch(`http://localhost/avalun/backend/toggle_lesson_completed.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userID,
          lesson_id: lessonID,
          ul_completed: currentStatus ? 0 : 1,
        }),
      });

      const result = await response.json();

      if (result.success) {
        setLessonDetails(prevDetails => ({
          ...prevDetails,
          ul_completed: !currentStatus
        }));
      }
    } catch (error) {
      console.error('Erro ao atualizar o status da lição:', error);
    }
  };

  useEffect(() => {
    fetch(`http://localhost/avalun/backend/lessons/get_lesson.php?lesson_id=${lessonID}&user_id=${userID}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro na resposta da rede');
        }
        return response.json();
      })
      .then(data => {
        if (data.message) {
          setError(data.message);
        } else {
          setLessonDetails({
            ...data,
            resources: data.resources || [] // Certifique-se de que resources seja sempre um array
          });
        }
      })
      .catch(error => {
        setError('Erro ao buscar os dados');
        console.error('Erro ao buscar os dados:', error);
      });
  }, [lessonID, userID]);

  if (error) {
    return (
      <div className="container mt-5">
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      </div>
    );
  }

  if (!lessonDetails) {
    return (
      <div className="container mt-5">
        <div>Carregando...</div>
      </div>
    );
  }

  const renderIcon = (type) => {
    switch (type) {
      case 'content':
        return <i className="bi bi-file-earmark-richtext"></i>;
      case 'link':
        return <i className="bi bi-link-45deg"></i>;
      case 'quiz':
        return <i className="bi bi-question-circle"></i>;
      case 'activity':
        return <i className="bi bi-pencil-square"></i>;
      case 'forum':
        return <i className="bi bi-chat-dots"></i>;
      default:
        return <i className="bi bi-file-earmark"></i>;
    }
  };

  return (
    <div className="container mt-5">
      <div className="card border-0 rounded-4 shadow-sm">
        <div className="card-header rounded-top-4 bg-primary text-white">
          <h1 className="card-title">{lessonDetails.lesson_title}</h1>
        </div>
        <div className="card-body">
          <p className="card-text">{lessonDetails.lesson_description}</p>
          <div className="lesson-content">
            <p>{lessonDetails.lesson_content}</p>
          </div>

          <h3 className="mt-4">Recursos e Atividades</h3>
          <ul className="list-group">
            {lessonDetails.resources.map(resource => (
              <li key={resource.id} className="list-group-item d-flex justify-content-between align-items-center">
                <div>
                  {renderIcon(resource.type)} 
                  <Link to={`./mod/${resource.type}/${resource.id}`} className="ms-2 text-decoration-none">
                    {resource.title}
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="card-footer rounded-bottom-4 d-flex justify-content-between align-items-center">
          <small className="text-muted">
            Última visualização: {lessonDetails.ul_last_viewed ? new Date(lessonDetails.ul_last_viewed).toLocaleString() : 'Nunca'}
          </small>
          <button
            onClick={() => handleToggleCompleted(lessonDetails.ul_completed)}
            className={`btn ${lessonDetails.ul_completed ? 'btn-success' : 'btn-outline-secondary'} rounded-pill`}
          >
            {lessonDetails.ul_completed ? 'Desfazer' : 'Concluir'}
            <i className={`ms-2 bi ${lessonDetails.ul_completed ? 'bi-check-circle' : 'bi-circle'}`}></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LessonPage;