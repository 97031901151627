import React from 'react';
import axios from 'axios';

const CoursesForm = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    axios.post('http://localhost/avalun/backend/courses/registerCourse.php', formData)
      .then(res => console.log(res.data))
      .catch(error => console.error('Error submitting form:', error));
  };

  return (
    <form onSubmit={handleSubmit} className="container">
      <div className="mb-3">
        <label htmlFor="name" className="form-label">Nome do Curso</label>
        <input type="text" className="form-control" id="name" name="name" placeholder='robotica, edição de videos,apresentação multimidia...' required />
      </div>

      <div className="mb-3">
        <label htmlFor="code" className="form-label">Código do Curso</label>
        <input type="text" className="form-control" id="code" name="code" placeholder='000' required />
      </div>

      <div className="mb-3">
        <label htmlFor="category" className="form-label">Categoria</label>
        <input type="text" className="form-control" id="category" name="category" placeholder='Marketing, Inovação, Administração...' required />
      </div>

      <div className="mb-3">
        <label htmlFor="visibility" className="form-label">Visibilidade</label>
        <input type="text" className="form-control" id="visibility" name="visibility" placeholder='Disponivel' required />
      </div>

      <div className="mb-3">
        <label htmlFor="start_date" className="form-label">Data de Início</label>
        <input type="date" className="form-control" id="start_date" name="start_date" required />
      </div>

      <div className="mb-3">
        <label htmlFor="end_date" className="form-label">Data de Término</label>
        <input type="date" className="form-control" id="end_date" name="end_date" required />
      </div>

      <div className="mb-3">
        <label htmlFor="modality" className="form-label">Modalidade</label>
        <select className="form-control" id="modality" name="modality" required >
          <option value="" disabled selected>Selecione a modalidade</option>
          <option value="presencial">Presencial</option>
          <option value="ead">EAD</option>
          <option value="online">Online</option>
        </select>
      </div>

      <div className="mb-3">
        <label htmlFor="summary" className="form-label">Resumo</label>
        <textarea className="form-control" id="summary" name="summary" required />
      </div>

      <div className="mb-3">
        <label htmlFor="image" className="form-label">Imagem do Curso</label>
        <input type="file" className="form-control" id="image" name="image" />
      </div>

      <div className="mb-3">
        <label htmlFor="format" className="form-label">Formato</label>
        <input type="text" className="form-control" id="format" name="format" required />
      </div>

      <div className="mb-3">
        <label htmlFor="sections" className="form-label">Seções</label>
        <input type="text" className="form-control" id="sections" name="sections" required />
      </div>

      <button type="submit" className="btn btn-primary">Cadastrar Curso</button>
    </form>
  );
};

export default CoursesForm;
