import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';

const ParticipantsList = ({ value }) => {
  const { classID } = useParams();
  const [participants, setParticipants] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`http://localhost/avalun/backend/courses/participants.php?class_id=${classID}`)
      .then(response => response.json())
      .then(data => {
        if (data.participants && Array.isArray(data.participants)) {
          const limitedParticipants = data.participants.slice(0, value);
          setParticipants(limitedParticipants);
        } else {
          setError('Nenhum participante encontrado ou dados inválidos.');
        }
      })
      .catch(error => setError('Erro ao buscar os participantes'));
  }, [classID, value]);

  const getBadgeClass = (lastActivity) => {
    if (!lastActivity || lastActivity === '0000-00-00 00:00:00') {
      return 'badge bg-secondary';
    }

    const minutesSinceLastActivity = moment().diff(moment(lastActivity, 'YYYY-MM-DD HH:mm:ss'), 'minutes');

    if (minutesSinceLastActivity <= 5) {
      return 'badge bg-success';
    } else if (minutesSinceLastActivity <= 15) {
      return 'badge bg-warning';
    } else {
      return 'badge bg-danger';
    }
  };

  return (
    <div className="mt-3">
      <h5>Participantes</h5>
      {error && <div className="alert alert-danger">{error}</div>}

      {Array.isArray(participants) && participants.length > 0 ? (
        <ul className="list-group rounded-4 shadow-sm">
          {participants.map(participant => (
            <li key={participant.user_id} className="list-group-item d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <img 
                  src={participant.user_photo ? `data:image/png;base64,${participant.user_photo}` : 'default_image.jpg'} 
                  alt={participant.user_name} 
                  className="rounded-circle me-3" 
                  style={{ width: '25px', height: '25px' }} 
                />
                <div style={{ fontSize: '0.85rem'}}>
                  <p className="fw-bold mb-0">{participant.user_name}</p>
                  <p className="mb-0 text-muted">Código: {participant.user_code}</p>
                </div>
              </div>
              <span className={getBadgeClass(participant.user_last_activity)}> </span>
            </li>
          ))}
          <li className="list-group-item d-grid gap-2"><Link to="./participantes" className="btn btn-primary">Ver todos os participantes</Link></li>
        </ul>
      ) : (
        <div className="alert alert-info">Nenhum participante encontrado.</div>
      )}
    </div>
  );
};

export default ParticipantsList;