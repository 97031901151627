import React, { useState } from 'react';
import axios from 'axios';

const LoginPage = () => {
    const [userAccess, setUserAccess] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [userRemember, setUserRemember] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            console.log('Dados enviados para o servidor:', {
                user_access: userAccess,
                user_password: userPassword,
                user_remember: userRemember
            });
            const response = await axios.post('http://localhost/avalun/backend/login.php', {
                user_access: userAccess,
                user_password: userPassword,
                user_remember: userRemember
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log('Resposta do servidor:', response.data);
            if (response.data.status === 'success') {
                localStorage.setItem('user_id', response.data.user_id);
                window.location.href = '/';
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            console.error('Erro ao se comunicar com o servidor:', error);
            setError('Erro no servidor');
        }
    };

    return (
        <div className="h-100">
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col-md-6 bg-white p-3 rounded-4 shadow-sm">
                    <h2>Acesse:</h2>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <form onSubmit={handleSubmit}>
                        <div className="form-group mb-3">
                            <label htmlFor="user_access">Código do Usuário ou Email</label>
                            <input
                                type="text"
                                className="form-control"
                                id="user_access"
                                placeholder="Código do Usuário ou Email"
                                value={userAccess}
                                onChange={(e) => setUserAccess(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="user_password">Senha</label>
                            <input
                                type="password"
                                className="form-control"
                                id="user_password"
                                placeholder="Senha"
                                value={userPassword}
                                onChange={(e) => setUserPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group form-check mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="user_remember"
                                checked={userRemember}
                                onChange={(e) => setUserRemember(e.target.checked)}
                            />
                            <label className="form-check-label" htmlFor="user_remember">Lembrar</label>
                        </div>
                        <div className="d-grid gap-2">
                            <button type="submit" className="btn btn-default rounded-pill">Acessar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;