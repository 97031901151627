import React, { useState, useEffect } from 'react';
import axios from 'axios';

const UserStatus = ({ userId }) => {
    const [userStatus, setUserStatus] = useState('');

    useEffect(() => {
        const fetchUserStatus = async () => {
            try {
                const response = await axios.get(`http://localhost/avalun/backend/get_user.php?status&user_id=${userId}`);
                const data = response.data;
                if (data.status === 'success') {
                    setUserStatus(data.user_status);
                } else {
                    console.error(data.message);
                }
            } catch (error) {
                console.error('Erro ao buscar status do usuário:', error);
            }
        };

        fetchUserStatus();
    }, [userId]);

    let statusColor;
    if (userStatus === 'online') {
      statusColor = 'success';
    } else if (userStatus === 'missing') {
      statusColor = 'warning';
    } else {
      statusColor = 'danger';
    }

    return (
        <div className="d-flex align-items-center">
            <i className={`bi bi-circle-fill bi-status text-${statusColor}`} title={`${userStatus}`}></i>
        </div>
    );
};

export default UserStatus;
