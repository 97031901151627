import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const AccountsList = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios.get('http://localhost/avalun/backend/accounts/list.php')
      .then(response => setUsers(response.data))
      .catch(error => console.error('Erro ao buscar os usuários:', error));
  }, []);

  return (
    <>
      <div className="container mt-5">
        <h1>Usuários no Sistema</h1>
        <div className="row g-4">
          <div className="col-md-12">
            <div className="card border-0 shadow-sm rounded-4">
              <div className="card-body">
                <ul>
                  {users.map(user => (
                    <li key={user.user_id}>
                      <strong>Nome:</strong> {user.user_name} <br />
                      <strong>Email:</strong> {user.user_email} <br />
                      <strong>Código:</strong> {user.user_code} <br />
                      <strong>Última Atividade:</strong> {user.user_last_activity || 'N/A'} <br />
                      <strong>Unidade:</strong> {user.user_unity} <br />
                      <strong>Cidade/Estado:</strong> {user.user_stateCity || 'N/A'} <br />
                      <strong>Telefone:</strong> {user.user_phone || 'N/A'} <br />
                      <strong><Link to={`/painel/contas/listar-usuario/detalhes-do-usuario/${user.user_id}`}>Detalhes</Link></strong> 
                      <hr />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr/>
      Ou
      <hr/>
       
      <div className="container mt-5">
      <h1>Usuários no Sistema</h1>
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>Nome</th>
              <th>CPF</th>
              <th>Email</th>
              <th>Unidade</th>
              <th>Código</th>
              <th>Última Atividade</th>
              <th>Cidade/Estado</th>
              <th>Telefone</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.user_id}>
                <td>{user.user_id}</td>
                <td>{user.user_name}</td>
                <td>{user.user_cpf}</td>
                <td>{user.user_email}</td>
                <td>{user.user_unity}</td>
                <td>{user.user_code}</td>
                <td>{user.user_last_activity || 'N/A'}</td>
                <td>{user.user_stateCity || 'N/A'}</td>
                <td>{user.user_phone || 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

    </>
  );
};

export default AccountsList;
