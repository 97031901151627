import React from 'react';

const CourseInfos = ({ formData }) => {
  const renderField = (label, value) => {
    if (value) {
      return (
        <>
          <dt>{label}</dt>
          <dd>{value}</dd>
        </>
      );
    }
    return null;
  };

  return (
    <div id="CourseInfos" className="">
      <dl>
        {renderField('Nome do Curso:', formData.name)}
        {renderField('Código do Curso:', formData.code)}
        {renderField('Categoria do Curso:', formData.category)}
        {renderField('Visibilidade do Curso:', formData.visibility)}
        {renderField('Data de Início do Curso:', formData.startDate)}
        {formData.hasEndDate && renderField('Data de Término do Curso:', formData.endDate)}
        {renderField('Modalidade do Curso:', formData.modality)}
        {renderField('Sumário do Curso:', formData.summary)}
        {renderField('Formato do Curso:', formData.format)}
        {formData.format === 'sections' && renderField('Número de Seções:', formData.sections)}
      </dl>
    </div>
  );
};

export default CourseInfos;
