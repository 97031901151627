import React, { useEffect } from 'react';

const UpdateLastViewed = ({ userId, classId }) => {
  useEffect(() => {
    const updateLastViewed = async () => {
      try {
        const response = await fetch('http://localhost/avalun/backend/update_last_viewed.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: userId,
            classId: classId,
          }),
        });

        const result = await response.json();
        if (result.success) {
          console.log('Última visualização atualizada com sucesso.');
        } else {
          console.log('Erro ao atualizar a última visualização.');
        }
      } catch (error) {
        console.error('Erro ao fazer a requisição:', error);
      }
    };

    updateLastViewed();
  }, [userId, classId]);

  return null; // Este componente não precisa renderizar nada
};

export default UpdateLastViewed;