import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import './i18n'; // Importa as configurações do i18n
import { useTranslation } from 'react-i18next';
import Breadcrumb from './components/Breadcrumb';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import UserActivityUpdater from './components/UserActivityUpdater';
import HomePage from './pages/HomePage';
import MyCoursesPage from './pages/MyCoursesPage';
import AdminPanel from './pages/painel/AdminPanel';
import MessagesPage from './pages/MessagesPage';
import LoginPage from './pages/LoginPage';
import Logout from './components/Logout'
// Accounts ----------------------
import ProfilePage from './pages/ProfilePage';
import Accounts from './pages/painel/accounts/Accounts';
import AccountsSignUp from './pages/painel/accounts/AccountsSignUp';
import PermissionsList from './pages/painel/accounts/PermissionsList';
import PermissionsRegister from './pages/painel/accounts/PermissionsRegister';
import AccountsList from './pages/painel/accounts/AccountsList';
import AccountsUserDetail from './pages/painel/accounts/AccountsUserDetail';
// Courses ---------------------------
import Courses from './pages/painel/courses/Courses';
import CourseNew from './pages/painel/courses/CourseNew';
import CourseList from './pages/painel/courses/CourseList';
import CoursesModalities from './pages/painel/courses/coursesModalities';
import CoursesCategories from './pages/painel/courses/CoursesCategories';
import CoursesClasses from './pages/painel/courses/CoursesClasses';
import CoursesClassesDetails  from './pages/painel/courses/CoursesClassesDetails';
import ClassView  from './pages/classes/Class.js';
import Attendance  from './pages/classes/Attendance.js';
import ParticipantsPage  from './pages/ParticipantsPage.js';
import Lesson  from './pages/LessonPage.js';
import Mod  from './pages/mod.js';
// Branches ---------------------------
import Branches from './pages/painel/branches/Branches';
import BranchRegister from './pages/painel/branches/BranchRegister';
// Notas ---------------------------
import Grades from './pages/painel/grades/Grades';
// Relatoios------------------------
import Reports from './pages/painel/reports/reports';
import ReportsFrequency from './pages/painel/reports/ReportsFrequency';

import './App.css';

const Preloader = () => (
  <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
    <div className="spinner-grow" style={{ width: '3rem', height: '3rem' }} role="status">
      <span className="visually-hidden">Carregando...</span>
    </div>
  </div>
);

const RequireAuth = ({ children }) => {
  const isAuthenticated = localStorage.getItem('user_id');
  return isAuthenticated ? children : <Navigate to="/login" />;
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const [Notification, setNotification] = useState(0);
  const location = useLocation();
  const isAuthenticated = !!localStorage.getItem('user_id');
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timeoutId);
  }, [location]);

  useEffect(() => {
    const fetchNotification = () => {
      const userId = localStorage.getItem('user_id');
      if (userId) {
        fetch(`http://localhost/avalun/backend/messages/actions.php?notification&user_id=${userId}`)
          .then(response => response.json())
          .then(data => {
            setNotification(data.Notification);
          }).catch(error => console.error('Error fetching new messages count:', error));
      }
    };

    const intervalId = setInterval(fetchNotification, 60000);
    fetchNotification();

    return () => clearInterval(intervalId);
  }, []);

  const startChat = (userId) => {
    console.log("Iniciando chat com usuário ID:", userId);
  };

  return (
    <>
      <UserActivityUpdater />
      {isAuthenticated && <NavBar Notification={Notification} />}
      <main className="flex-shrink-0">
        <div className="container mt-4">
          {isAuthenticated && (
            <div>
              <Breadcrumb />
            </div>
          )}
          {loading ? (
            <Preloader />
          ) : (
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/" element={<RequireAuth> <HomePage startChat={startChat} /> </RequireAuth>} />
              <Route path="/cursos" element={<RequireAuth> <MyCoursesPage /> </RequireAuth>} />
              <Route path="/cursos/:classID" element={<RequireAuth> <ClassView /> </RequireAuth>} />
              <Route path="/cursos/:classID/l/:lessonID" element={<RequireAuth> <Lesson /> </RequireAuth>} />
              <Route path="/cursos/:classID/l/:lessonID/mod/:type/:id" element={<RequireAuth> <Mod /> </RequireAuth>} />
              <Route path="/cursos/:classID/presenca/:classID" element={<RequireAuth> <Attendance /> </RequireAuth>} />
              <Route path="/cursos/:classID/participantes" element={<ParticipantsPage />} />
  
              <Route path="/mensagens" element={<RequireAuth> <MessagesPage /> </RequireAuth>} />
              
              <Route path="/perfil/*" element={<RequireAuth> <ProfilePage /> </RequireAuth>} />
  
              <Route path="/painel" element={<RequireAuth> <AdminPanel /> </RequireAuth>} >
  
                <Route path="/painel/contas" element={<Accounts />}/>
                <Route path="/painel/contas/cadastrar-usuario" element={<AccountsSignUp />} />
                <Route path="/painel/contas/listar-permissao" element={<PermissionsList />} />
                <Route path="/painel/contas/cadastrar-permissao" element={<PermissionsRegister />} />
                <Route path="/painel/contas/listar-usuario" element={<AccountsList />} />
                <Route path="/painel/contas/listar-usuario/detalhes-do-usuario/:id" element={<AccountsUserDetail/>} />
  
                <Route path="/painel/cursos" element={<Courses />}/>
                <Route path="/painel/cursos/novo" element={<RequireAuth> <CourseNew /> </RequireAuth>} />
                <Route path="/painel/cursos/listar" element={<CourseList />} />
                <Route path="/painel/cursos/categorias" element={<CoursesModalities />} />
                <Route path="/painel/cursos/modalidades" element={<CoursesCategories />} />
                <Route path="/painel/cursos/listar-turmas" element={<CoursesClasses />} />
                <Route path="/painel/cursos/listar-turmas/detalhes-da-turma/:id" element={<CoursesClassesDetails/>} />
  
                <Route path="unidades" element={<Branches />}/>
                  <Route path="/painel/unidades/cadastrar-unidade" element={<BranchRegister/>} />
  
                {/* <Route path="" element={</> } /> */}
                <Route path="notas" element={<Grades />}></Route>
                <Route path="relatorios" element={<Reports />}></Route>
  
              </Route>
            </Routes>
          )}
        </div>
      </main>
      <Footer />
    </>
  );  
}

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;