import React from 'react';
import { Link } from 'react-router-dom';
import PanelNavBar from '../../../components/PanelNavBar';



const Grades = () => {
  return (
    <>
      
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <div>
                  <h1 className="mb-0">Notas</h1>
                  <small className="text-muted">Gerêncie os Notas:</small>
                </div>
              </div>
              <div className='painel__container'>
                <div>
                  <h2>Turmas</h2>
                  <ul>
                    <li><Link to="./">Notas por Turmas</Link></li>
                  </ul>
                </div>
                <hr />
              </div>
            </div>
          </div>
    </>


  );
};

export default Grades;

