import React, { useState } from 'react';
import AccountsForm from '../../../components/accounts/AccountsForm';
import AccountsInfos from '../../../components/accounts/AccountsInfos';


const AccountsSignUp = () => {
  const [formData, setFormData] = useState({});

  const handleFormChange = (updatedForm) => {
    setFormData(updatedForm);
  };
  
  return (
    <>
      
    <div className="container mt-5">
      <h1>Novo Usuário</h1>
      <div className="row g-4">
        <div className="col-md-8">
          <div className="card border-0 shadow-sm rounded-4">
            <div className="card-body">
              <AccountsForm onFormChange={handleFormChange} />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card border-0 shadow-sm rounded-4 sticky-top">
            <div className="card-header rounded-top-4">
              <h5 className="mb-0">Informações do Usuário:</h5>

            </div>
            <div className="card-body" id="CourseInfos">
            <AccountsInfos formData={formData} />
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default AccountsSignUp;

