import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CoursesCategories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios.get('http://localhost/avalun/backend/courses/listCoursesCategories.php')
      .then(response => setCategories(response.data))
      .catch(error => console.error('Erro ao buscar as Categorias:', error));
  }, []);


  return (
    <>
      {categories.map(category => (
        <li key={category.id}>
          {category.name}
          <hr />
        </li>
      ))}
    </>
  );
};

export default CoursesCategories;

