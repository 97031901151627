import React from 'react';

const Footer = () => {
  return (
      <footer id="Footer" className="sticky-bottom">
        <div className="container">
            <div className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4">
                <p className="col-md-4 mb-0 text-body-secondary">© 2024 Avalun</p>

                <a href="/" className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                <img src="/img/symbol-avalun.png" className="logo-site img-fluid" alt="Avalun" />
                </a>

                <ul className="nav col-md-4 justify-content-end">
                    <li className="nav-item"><a href="!#" className="nav-link px-2 text-body-secondary">Privacidade</a></li>
                    <li className="nav-item"><a href="!#" className="nav-link px-2 text-body-secondary">Termos</a></li>
                    <li className="nav-item"><a href="!#" className="nav-link px-2 text-body-secondary">FAQs</a></li>
                    <li className="nav-item"><a href="!#" className="nav-link px-2 text-body-secondary">Suporte</a></li>
                </ul>
            </div>
        </div>
      </footer>
  );
};

export default Footer;