import React, { useState, useEffect } from 'react';
import { Pagination } from 'react-bootstrap';
import { format } from 'date-fns';

const ProfileLog = ({ user }) => {
  const [logs, setLogs] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [visibleCount, setVisibleCount] = useState(5);
  const logsPerPage = 2;

  useEffect(() => {
    if (user && user.user_id) {
      fetch(`http://localhost/avalun/backend/accounts/logs.php?user_id=${user.user_id}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Erro na resposta da rede');
          }
          return response.json();
        })
        .then(data => {
          if (data.message) {
            setError(data.message);
          } else {
            setLogs(data);
          }
        })
        .catch(error => {
          setError('Erro ao buscar os dados');
          console.error('Erro ao buscar os dados:', error);
        });
    } else {
      setError('ID do usuário não fornecido');
    }
  }, [user]);

  const getBrowserName = (userAgent) => {
    if (userAgent.includes('AvalunApp/')) {
      return {
        name: 'Avalun App',
        icon: 'bi-phone',
      };
    } else if (userAgent.includes('Edg/')) {
      return {
        name: 'Microsoft Edge',
        icon: 'bi-browser-edge',
      };
    } else if (userAgent.includes('Chrome/')) {
      return {
        name: 'Google Chrome',
        icon: 'bi-browser-chrome',
      };
    } else if (userAgent.includes('Safari/') && !userAgent.includes('Chrome/')) {
      return {
        name: 'Safari',
        icon: 'bi-browser-safari',
      };
    } else if (userAgent.includes('Firefox/')) {
      return {
        name: 'Mozilla Firefox',
        icon: 'bi-browser-firefox',
      };
    } else if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
      return {
        name: 'Internet Explorer',
        icon: 'bi-browser-internet-explorer',
      };
    } else {
      return {
        name: 'Navegador Desconhecido',
        icon: 'bi-question-circle',
      };
    }
  };

  const filteredLogs = logs.filter(activity =>
    activity.log_action.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const groupedLogs = filteredLogs.reduce((acc, activity) => {
    const date = new Date(activity.log_added);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    let key;
    if (date.toDateString() === today.toDateString()) {
      key = 'Hoje';
    } else if (date.toDateString() === yesterday.toDateString()) {
      key = 'Ontem';
    } else {
      key = date.toLocaleDateString();
    }

    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(activity);
    return acc;
  }, {});

  const logDates = Object.keys(groupedLogs);

  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = logDates.slice(indexOfFirstLog, indexOfLastLog);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleShowMore = () => {
    setVisibleCount(prevCount => prevCount + 5);
  };

  return (
    <div className="col-lg-12 col-md-12 col-12">
      <div className="row align-items-center mb-6">
        <div className="col-md-12 col-12 mb-3">
          <form>
            <input
              type="search"
              className="form-control"
              placeholder="Procurar uma atividade:"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </form>
        </div>
      </div>
      {error ? (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      ) : (
        currentLogs.map(date => (
          <div className="mb-2" key={date}>
            <div className="card">
              <table className="table table-striped table-hover table-sm align-middle">
                <caption className="caption-top text-center">{date}</caption>
                <thead>
                  <tr>
                    <th>Usuário</th>
                    <th>Data</th>
                    <th>Ação</th>
                    <th>IP</th>
                    <th>Navegador</th>
                  </tr>
                </thead>
                <tbody>
                  {groupedLogs[date].slice(0, visibleCount).map(activity => (
                    <tr key={activity.log_id}>
                      <td>
                        <div className="d-flex align-items-center flex-row">
                          <div>
                            <img src={`data:image/jpeg;base64,${activity.user_photo}`} alt={`${activity.user_name}`} className="avatar-sm rounded-circle" style={{ maxWidth: '25px' }} />
                          </div>
                          <span className="ms-2">{activity.user_name}</span>
                        </div>
                      </td>
                      <td>{format(new Date(activity.log_added), 'dd/MM/yyyy HH:mm:ss')}</td>
                      <td>{activity.log_action}</td>
                      <td>{activity.log_ip}</td>
                      <td>
                        <i className={`bi ${getBrowserName(activity.log_browser).icon}`}></i>
                        <small className="ms-2">{getBrowserName(activity.log_browser).name}</small>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {groupedLogs[date].length > visibleCount && (
                  <tfoot>
                    <tr>
                      <td colSpan="5" className="text-center">
                        <button className="btn btn-primary" onClick={handleShowMore}>
                          Mostrar mais
                        </button>
                      </td>
                    </tr>
                  </tfoot>
                )}
              </table>
            </div>
          </div>
        ))
      )}
      <Pagination className="justify-content-center mt-4">
        {Array.from({ length: Math.ceil(logDates.length / logsPerPage) }, (_, i) => (
          <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => paginate(i + 1)}>
            {i + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </div>
  );
};

export default ProfileLog;