import React from 'react';
import { Outlet, Link } from 'react-router-dom';



const Accounts = () => {
  return (
    <>
      <div>
        <div className=''>
          <div className='row'>
            <div className='col-12'>
              <div className='d-flex justify-content-between align-items-center mb-4'>
                <div>
                  <h1 className='mb-0'>Contas</h1>
                  <small className='text-muted'>Gerencie os usuários do AVALUN:</small>
                </div>
              </div>

              <div className=''>
                <div>
                  <h2>Usuários</h2>
                  <ul>
                    <li><Link to="/painel/contas/cadastrar-usuario">Cadastrar novo Usuário</Link></li>
                    <li><Link to="/painel/contas/listar-usuario">Listar Usuários</Link></li>
                  </ul>
                </div>
                <hr/>
                <div>
                  <h2>Permissões</h2>
                  <ul>
                    <li><Link to="/painel/contas/listar-permissao">Cadastrar nova Permissão</Link></li>
                    <li><Link to="/painel/contas/cadastrar-permissao">Gerênciar Permissões</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </>
  );
};

export default Accounts;

