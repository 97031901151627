import React from 'react';
import { Link } from 'react-router-dom';



const CoursesModalities = () => {
  return (
    <>
      Courses Modalities
    </>
  );
};

export default CoursesModalities;

