import React, { useState, useEffect } from 'react';
import { Link, Route, Routes, Navigate } from 'react-router-dom';
import logEvent from '../components/Logger';
import ProfileView from '../components/accounts/ProfileView';
import ProfileEdit from '../components/accounts/ProfileEdit';
import ProfileLog from '../components/accounts/ProfileLog';

const RequireAuth = ({ children }) => {
  const userId = localStorage.getItem('user_id');
  return userId ? children : <Navigate to="/login" />;
};

const ProfilePage = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const userId = localStorage.getItem('user_id');

  useEffect(() => {
    if (!userId) {
      setError('Usuário não autenticado');
      return;
    }
    logEvent(userId, 'Acessou o perfil');

    fetch(`http://localhost/avalun/backend/accounts/get.php?user_id=${userId}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro ao buscar os dados do usuário');
        }
        return response.json();
      })
      .then(data => {
        if (data.message) {
          setError(data.message);
        } else {
          setUser(data);
        }
      })
      .catch(error => {
        setError('Erro ao buscar os dados');
        console.error('Erro ao buscar os dados:', error);
      });
  }, [userId]);

  if (error) {
    return <div className="alert alert-danger" role="alert">{error}</div>;
  }

  if (!user) {
    return <div className="alert alert-info" role="alert">Carregando dados...</div>;
  }

  return (
    <div className="container flex-grow-1">
      <div className="d-flex align-items-center p-4 mb-3" id="profile">
        <img src={`data:image/jpeg;base64,${user.user_photo}`} alt={`${user.user_name}`} className="d-block ui-w-100 rounded-circle" style={{ maxWidth: '100px' }} />
        <div className="media-body ms-4">
          <h4 className="fw-bold mb-0">{user.user_name}</h4>
          <div className="text-muted mb-2">Código: {user.user_code}</div>
        </div>
        <div className="ms-auto">
          <ul className="nav nav-pills">
            <li className="nav-item">
              <Link className="nav-link" to="/perfil">Perfil</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/perfil/editar">Editar</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/perfil/historico">Histórico</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/logout">Sair</Link>
            </li>
          </ul>
        </div>
      </div>
      <Routes>
        <Route path="/" element={<RequireAuth><ProfileView user={user} /></RequireAuth>} />
        <Route path="editar" element={<RequireAuth><ProfileEdit user={user} /></RequireAuth>} />
        <Route path="historico" element={<RequireAuth><ProfileLog user={user} /></RequireAuth>} />
      </Routes>
    </div>
  );
};

export default ProfilePage;