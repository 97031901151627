import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './NavBar.css';
import axios from 'axios';

const NavBar = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [newMessagesCount, setNewMessagesCount] = useState(0);
  const userId = localStorage.getItem('user_id');
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode) {
      setDarkMode(savedMode === 'true');
      document.body.classList.toggle('bg-dark', savedMode === 'true');
      document.body.classList.toggle('text-white', savedMode === 'true');
      document.querySelector('#Menu').classList.toggle('theme-dark', savedMode === 'true');
    }

    const fetchNewMessagesCount = async () => {
      try {
        const response = await axios.get(`http://localhost/avalun/backend/messages/actions.php?notification&user_id=${userId}`);
        setNewMessagesCount(response.data.newMessagesCount);
      } catch (error) {
        console.error('Error fetching new messages count:', error);
      }
    };

    fetchNewMessagesCount();
    const interval = setInterval(fetchNewMessagesCount, 5000);
    return () => clearInterval(interval);
  }, [userId]);

  const toggleDarkMode = () => {
    setDarkMode(prevMode => {
      const newMode = !prevMode;
      document.body.classList.toggle('bg-dark', newMode);
      document.body.classList.toggle('text-white', newMode);
      localStorage.setItem('darkMode', newMode);
      return newMode;
    });
  };

  return (
    <header className="">
      <nav className="navbar py-2 py-sm-0 bg-dark bg-default sticky-md-top">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center w-100">
            <a className="navbar-brand d-flex align-items-center" href="!#" role="button">
              <img src="/img/symbol-avalun.png" className="logo-site img-fluid" alt="Avalun" />
              <div className="ms-2 d-none d-md-block text-white">AVALUN</div>
            </a>
            <ul className="nav mx-auto mb-lg-0" id="Menu">
              <li className="nav-item">
                <NavLink className="nav-link py-3 d-flex align-items-center justify-content-center" activeclassname="active" exact="true" to="/" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Página Inicial">
                  <i className="bi bi-house-door fs-3"></i>
                  <div className="ms-2 d-none d-lg-block">Página Inicial</div>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link py-3 d-flex align-items-center justify-content-center" activeclassname="active" to="/cursos" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Meus Cursos">
                  <i className="bi bi-book fs-3"></i>
                  <div className="ms-2 d-none d-lg-block">Meus Cursos</div>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link py-3 d-flex align-items-center justify-content-center" activeclassname="active" to="/painel" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Painel">
                  <i className="bi bi-columns fs-3"></i>
                  <div className="ms-2 d-none d-lg-block">Painel</div>
                </NavLink>
              </li>
            </ul>
            <ul className="nav" id="AccountNav">
              <li className="nav-item">
                <button
                  className="btn btn-outline-light btn-round fs-6"
                  onClick={toggleDarkMode}
                >
                  <i className={darkMode ? "bi bi-sun-fill" : "bi bi-moon-fill"}></i>
                </button>
              </li>
              <li className="nav-item">
                <a
                  href="#!"
                  className="btn btn-outline-light btn-round fs-6"
                >
                  <i className="bi bi-bell-fill"></i>
                </a>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/mensagens"
                  className="btn btn-outline-light btn-round fs-6 position-relative"
                >
                  <i className="bi bi-chat-fill"></i>
                  {newMessagesCount > 0 && (
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-danger">
                      {newMessagesCount}
                    </span>
                  )}
                </NavLink>
              </li>
              <li className="nav-item">
                <div className="dropdown">
                  <button type="button" className="btn btn-outline-light dropdown-toggle rounded-pill" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="bi bi-person-fill"></i>
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end rounded-4 shadow-sm">
                    <li><a className="dropdown-item" href="/perfil">Perfil</a></li>
                    <li><a className="dropdown-item" href="/notas">Notas</a></li>
                    <li><a className="dropdown-item" href="/calendario">Calendário</a></li>
                    <li><a className="dropdown-item" href="/relatorios">Relatórios</a></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="dropdown-item" href="/calendario">Configurações</a></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li className="dropdown-submenu">
                      <button type="button" className="dropdown-item dropdown-toggle">
                        {t('language')}
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <button className="dropdown-item" onClick={() => changeLanguage('pt')}>Português</button>
                        </li>
                        <li>
                          <button className="dropdown-item" onClick={() => changeLanguage('en')}>Inglês</button>
                        </li>
                        <li>
                          <button className="dropdown-item" onClick={() => changeLanguage('es')}>Espanhol</button>
                        </li>
                      </ul>
                    </li>              
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="dropdown-item" href="/logout">Sair</a></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default NavBar;