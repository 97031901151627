import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./RichTextEditor.css";

export default class RichTextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "",
    };
  }

  handleChange = (content) => {
    this.setState({ content });
    console.log("Conteúdo alterado:", content);
  };

  imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (file && file.size <= 2 * 1024 * 1024) { // Limite de 2 MB
        const formData = new FormData();
        formData.append("image", file);

        try {
          const response = await fetch("seu_backend.php", {
            method: "POST",
            body: formData,
          });
          const result = await response.json();

          if (result.success) {
            const range = this.quillRef.getEditor().getSelection();
            this.quillRef.getEditor().insertEmbed(range.index, "image", result.url);
          } else {
            alert("Falha ao fazer o upload da imagem.");
          }
        } catch (error) {
          console.error("Erro ao enviar imagem:", error);
        }
      } else {
        alert("A imagem deve ter no máximo 2 MB.");
      }
    };
  };

  modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
      ],
      handlers: {
        image: this.imageHandler, // Callback para upload
      },
    },
  };

  render() {
    const { id, name } = this.props;

    return (
      <div>
        <ReactQuill
          ref={(el) => (this.quillRef = el)}
          id={id}
          name={name}
          value={this.state.content}
          onChange={this.handleChange}
          placeholder="Comece a digitar..."
          modules={this.modules}
          className="quill-bootstrap"
        />
      </div>
    );
  }
}