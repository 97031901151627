import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const ClassDetails = () => {
  const { id } = useParams();
  const [c_class, setClass] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(`http://localhost/avalun/backend/courses/listCoursesClassesDetails.php?id=${id}`)
      .then(response => {
        setClass(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Erro ao buscar os detalhes da turma:', error);
        setError(error.message);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <div className="container mt-5"><p>Carregando...</p></div>;
  }

  if (error) {
    return <div className="container mt-5"><p>Erro: {error}</p></div>;
  }

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Detalhes da Turma</h1>
      {c_class && (
        <div className="card border-0 shadow-sm rounded-4">
          <div className="card-body">
            <h5 className="card-title">{c_class.class_name}</h5>
            <h6 className="card-subtitle mb-2 text-muted">{c_class.class_code}</h6>
            <p className="card-text"><strong>Descrição:</strong> {c_class.class_description}</p>
            <p className="card-text"><strong>Hora:</strong> {c_class.class_time}</p>
            <p className="card-text"><strong>Data:</strong> {c_class.class_date}</p>
            <p className="card-text"><strong>Início:</strong> {c_class.class_start}</p>
            <p className="card-text"><strong>Fim:</strong> {c_class.class_end}</p>
            <p className="card-text"><strong>Adicionado:</strong> {c_class.class_added}</p>
            <p className="card-text"><strong>Status:</strong> {c_class.class_status}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassDetails;
