import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const CoursesClasses = () => {
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get('http://localhost/avalun/backend/courses/listCoursesClasses.php')
      .then(response => {
        if (Array.isArray(response.data)) {
          setClasses(response.data);
        } else {
          throw new Error('Resposta da API não é um array');
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Erro ao buscar as Categorias:', error);
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div className="container mt-5"><p>Carregando...</p></div>;
  }

  if (error) {
    return <div className="container mt-5"><p>Erro: {error}</p></div>;
  }

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Lista das Turmas</h1>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Código</th>
            <th>Nome</th>
            <th>Descrição</th>
            <th>Imagem</th>
            <th>Hora</th>
            <th>Data</th>
            <th>Início</th>
            <th>Fim</th>
            <th>Adicionado</th>
            <th>Status</th>
            <th>Detalhes</th>
          </tr>
        </thead>
        <tbody>
          {classes.map(c_class => (
            <tr key={c_class.class_id}>
              <td>{c_class.class_id}</td>
              <td>{c_class.class_code}</td>
              <td>{c_class.class_name}</td>
              <td>{c_class.class_description}</td>
              <td><img src={c_class.class_image} alt={c_class.class_name} style={{ width: '50px', height: '50px' }} /></td>
              <td>{c_class.class_time}</td>
              <td>{c_class.class_date}</td>
              <td>{c_class.class_start}</td>
              <td>{c_class.class_end}</td>
              <td>{c_class.class_added}</td>
              <td>{c_class.class_status}</td>
              <td><Link to={`/painel/courses/listar-turmas/detalhes-da-turma/${c_class.class_id}`}>Detalhes</Link></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CoursesClasses;
