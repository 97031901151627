import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import ProgressBar from '../../components/ProgressBar';
import ParticipantsList from '../../components/ParticipantsList';
import NoticesModal from '../../components/NoticesModal';
import UpdateLastViewed from '../../components/UpdateLastViewed';
import './Class.css';

const ClassPage = () => {
  const userID = localStorage.getItem('user_id');
  const { classID } = useParams(); // Pega o ID da turma da URL
  const [classDetails, setClassDetails] = useState(null);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(50); // Valor inicial de 50% 
  const [modalShow, setModalShow] = useState(false);

  // Função para abrir o modal
  const handleShow = () => setModalShow(true);
  // Função para fechar o modal
  const handleClose = () => setModalShow(false);

  const handleToggleCompleted = async (lessonId, currentStatus) => {
    try {
      const response = await fetch(`http://localhost/avalun/backend/toggle_lesson_completed.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userID,  // Passando o userID corretamente
          lesson_id: lessonId,
          ul_completed: currentStatus ? 0 : 1, // Passa 0 se estiver feito, 1 se não estiver
        }),
      });

      const result = await response.json();

      if (result.success) {
        // Atualizar o estado localmente após o sucesso da operação no backend
        setClassDetails(prevDetails => ({
          ...prevDetails,
          lessons: prevDetails.lessons.map(lesson =>
            lesson.lesson_id === lessonId ? { ...lesson, ul_completed: !currentStatus } : lesson
          )
        }));
      }
    } catch (error) {
      console.error('Erro ao atualizar o status da lição:', error);
    }
  };

  useEffect(() => {
    fetch(`http://localhost/avalun/backend/courses/class.php?class_id=${classID}&user_id=${userID}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro na resposta da rede');
        }
        return response.json();
      })
      .then(data => {
        if (data.message) {
          setError(data.message);
        } else {
          setClassDetails(data);
        }
      })
      .catch(error => {
        setError('Erro ao buscar os dados');
        console.error('Erro ao buscar os dados:', error);
      });
  }, [classID, userID]);

  return (
    <>
      <UpdateLastViewed userId={userID} classId={classID} />
      <div className="bg-dark rounded-4">
        <div className="row p-4">
          <div className="col-md-8">
            <div className="d-flex flex-column align-content-between">
              <h1 className="text-white text-shadow">{classDetails?.class_name || 'Nome do Módulo'}</h1>
              <p className="text-white flex-grow-1 h-100">{classDetails?.class_description || 'Descrição do módulo'}</p>
              <div className="row row-cols-5 gx-2 flex-shrink-1 mt-auto">
                <button type="button" className="btn btn-outline-light"><i className="bi bi-menu-down me-2"></i>Conteúdo</button>
                <button type="button" className="btn btn-outline-light" onClick={handleShow}><i className="bi bi-info-circle me-2"></i>Avisos</button>
                <button type="button" className="btn btn-outline-light"><i className="bi bi-journal-richtext me-2"></i>Apostila</button>
                <button type="button" className="btn btn-outline-light"><i className="bi bi-graph-up-arrow me-2"></i>Notas</button>
                <Link to={`./presenca/${classID}`} className="btn btn-outline-light"><i className="bi bi-person-check me-2"></i>Presença</Link>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <figure className="course-book">

            </figure>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        {error ? (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        ) : classDetails ? (
          <>
            <div className="row">
              <div className="col-md-8">
                <div className="row row-cols-1 row-cols-md-2 g-4">
                  {classDetails.lessons.map((lesson, index) => (
                    <div className="col" key={lesson.lesson_id}>
                      <div className="card h-100 border-0 rounded-4 shadow-sm">
                        <div className="card-header rounded-top-4">
                          <div className="d-flex justify-content-between align-items-center">
                            {/* Exibindo o número da aula baseado no índice */}
                            <b>{index + 1}</b>
                            <Link 
                              to={`./l/${lesson.lesson_id}`} 
                              className="btn btn-sm rounded-pill btn-primary"
                            >
                              Acessar<i className="bi bi-box-arrow-in-right ms-2"></i>
                            </Link>
                          </div>
                        </div>
                        <div className="card-body">
                          <h5 className="card-title">{lesson.lesson_title}</h5>
                          <p className="card-text">{lesson.lesson_description}</p>
                        </div>
                        <div className="card-footer rounded-bottom-4">
                          <div className="d-flex justify-content-between align-items-center">
                            <small className="text-body-secondary">{lesson.ul_last_viewed ? `Visto em: ${lesson.ul_last_viewed}` : 'Acesse!'}</small>
                            <small>
                              {lesson.ul_completed ? 'Feito' : 'Falta fazer'}
                            <i className={`ms-2 bi ${lesson.ul_completed ? 'bi-check-circle' : 'bi-circle'}`}></i>
                              </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-4">
                <p>Aqui está o seu progresso no módulo:</p>
                <ProgressBar value={progress} />
                <ParticipantsList value={5} />
              </div>
            </div>
          </>
        ) : (
          <div>Carregando...</div>
        )}
        <NoticesModal show={modalShow} handleClose={handleClose} />
      </div>
    </>
  );
};

export default ClassPage;