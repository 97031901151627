import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto'; // Importe o Chart.js
import { Outlet} from 'react-router-dom'; // Importando useNavigate
import PanelNavBar from '../../components/PanelNavBar';

const MyChart = () => {
  const chartRef = useRef(null); // Referência para o canvas

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    // Criação do gráfico Chart.js
    const myChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday'
        ],
        datasets: [{
          data: [
            15339,
            21345,
            18483,
            24003,
            23489,
            24092,
            12034
          ],
          lineTension: 0,
          backgroundColor: 'transparent',
          borderColor: '#007bff',
          borderWidth: 4,
          pointBackgroundColor: '#007bff'
        }]
      },
      options: {
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            boxPadding: 3
          }
        }
      }
    });

    // Limpeza do gráfico ao desmontar o componente
    return () => myChart.destroy();
  }, []);

  return (
    <div>
      <canvas ref={chartRef} id="myChart" />
    </div>
  );
};

const AdminPanel = () => {

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="sidebar-panel border rounded-4 shadow col-md-3 col-lg-2 p-0 bg-body-tertiary">
        <PanelNavBar />
        </div>

        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default AdminPanel;
