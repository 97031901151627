import React, { useEffect, useRef, useState } from 'react';
import UserStatus from '../components/UserStatus';
import logEvent from '../components/Logger';
import axios from 'axios';

const MessagesPage = () => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [receiverId, setReceiverId] = useState(null);
  const [conversationUsers, setConversationUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [shouldScroll, setShouldScroll] = useState(true);
  const userId = localStorage.getItem('user_id');
  const chatHistoryRef = useRef(null);

  useEffect(() => {
    if (!userId) {
      setError('Usuário não autenticado');
      return;
    }
    logEvent(userId, 'Visualizou suas mensagens');

    const fetchConversationUsers = async () => {
      if (userId) {
        try {
          const response = await axios.get(`http://localhost/avalun/backend/messages/actions.php?conversations&user_id=${userId}`);
          if (Array.isArray(response.data)) {
            setConversationUsers(response.data);
          } else {
            setConversationUsers([]);
          }
        } catch (error) {
          console.error('Error fetching conversation users:', error);
        }
      }
    };
    fetchConversationUsers();
  }, [userId]);

  useEffect(() => {
    if (receiverId && userId) {
      const fetchMessages = async () => {
        try {
          const response = await axios.get(`http://localhost/avalun/backend/messages/actions.php?messages&user_id=${userId}&receiver_id=${receiverId}`);
          if (Array.isArray(response.data)) {
            setMessages(response.data);
            markMessagesAsRead(response.data); // Chame a função aqui para marcar as mensagens como lidas
          } else {
            setMessages([]);
          }
        } catch (error) {
          console.error('Error fetching messages:', error);
        }
      };
      fetchMessages();

      const interval = setInterval(fetchMessages, 3000);
      return () => clearInterval(interval);
    }
  }, [receiverId, userId]);

  useEffect(() => {
    if (shouldScroll && chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [messages, shouldScroll]);

  const markMessagesAsRead = async (messages) => {
    const unreadMessageIds = messages
      .filter(msg => msg.message_receiver == userId && msg.message_read == 0)
      .map(msg => msg.message_id);

    if (unreadMessageIds.length > 0) {
      try {
        await axios.post('http://localhost/avalun/backend/messages/actions.php?read=true', {
          message_ids: unreadMessageIds
        });
      } catch (error) {
        console.error('Error marking messages as read:', error);
      }
    }
  };

  const sendMessage = async () => {
    if (!receiverId || !message.trim()) {
      console.error('Receiver ID is not set or message is empty');
      return;
    }

    try {
      await axios.post('http://localhost/avalun/backend/messages/actions.php?send=true', {
        message_sender: userId,
        message_receiver: receiverId,
        message_content: message
      });
      setMessage('');
      setShouldScroll(true);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleUserSelect = (user) => {
    setReceiverId(user.user_id);
    setSelectedUser(user);
    setShouldScroll(true);
  };

  const filteredUsers = conversationUsers.filter(user => user.user_name.toLowerCase().includes(searchTerm.toLowerCase()));

  const handleScroll = () => {
    if (chatHistoryRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatHistoryRef.current;
      if (scrollHeight - scrollTop === clientHeight) {
        setShouldScroll(true);
      } else {
        setShouldScroll(false);
      }
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div>
              <h1 className="mb-0">Mensagens</h1>
              <small className="text-muted">Converse com estudantes e instrutores:</small>
            </div>
          </div>
        </div>
        <div className="col-12 bg-white rounded-4 shadow-sm p-4">
          <div className="row chat-app">
            <div id="plist" className="people-list col-md-4">
              <div className="input-group mb-3">
                <span className="input-group-text"><i className="bi bi-search"></i></span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Procurar..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <ul className="list-unstyled chat-list mt-2 mb-0">
                {filteredUsers.map(user => (
                  <li
                    className="clearfix mb-2 d-flex justify-content-center align-items-center"
                    key={user.user_id}
                    onClick={() => handleUserSelect(user)}
                  >
                    <img src={`https://bootdey.com/img/Content/avatar/avatar${user.user_id % 5 + 1}.png`} alt="avatar" className="rounded-circle" style={{ height: '50px' }} />
                    <div className="name ms-2">{user.user_name}</div>
                    <div className="status ms-auto"> <UserStatus userId={user.user_id} /> </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="chat col-md-8 d-flex flex-column">
              <div className="chat-header clearfix">
                <div className="row">
                  <div className="col-lg-6">
                    {selectedUser && (
                      <>
                        <div className="chat-about d-flex align-items-center">
                          <img src={`https://bootdey.com/img/Content/avatar/avatar${selectedUser.user_id % 5 + 1}.png`} alt="avatar" className="rounded-circle" style={{ height: '50px' }} />
                          <div className="chat-details ms-2">
                            <h6 className="mb-0">{selectedUser.user_name}</h6>
                            <small>Last seen: 2 hours ago</small>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="chat-history mb-auto" ref={chatHistoryRef} onScroll={handleScroll}>
                <div className="m-b-0">
                  {messages.length > 0 ? (
                    messages.map((msg, index) => {
                      const isPreviousDateSame = index > 0 && new Date(messages[index - 1].message_date).toDateString() === new Date(msg.message_date).toDateString();
                      return (
                        <React.Fragment key={msg.message_id}>
                          {!isPreviousDateSame && (
                            <div className="clearfix text-center mt-2 mb-2">
                              <span className="badge bg-secondary">
                                {new Date(msg.message_date).toLocaleDateString()}
                              </span>
                            </div>
                          )}
                          <div className={`clearfix ${msg.message_sender == userId ? 'text-end' : ''}`}>
                            <div className={`message-data d-flex align-items-center ${msg.message_sender == userId ? 'flex-row-reverse text-start' : ''}`}>
                              <img
                                src={`https://bootdey.com/img/Content/avatar/avatar${msg.message_sender == userId ? '7' : '2'}.png`}
                                alt="avatar"
                                className="rounded-circle mx-2"
                                style={{ height: '25px' }}
                              />
                              <div className={`message ${msg.message_sender == userId ? 'bg-success-subtle' : 'bg-secondary-subtle'}`}>
                                {msg.message_content}
                                <span className="message-time">
                                  {new Date(msg.message_date).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}
                                  {msg.message_sender == userId && (
                                    <span className="ms-1 fs-6">
                                      <i className={`bi ${msg.message_read ? 'text-primary bi-check-all' : 'text-secondary bi-check'}`}></i>
                                    </span>
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <p>Nenhuma mensagem encontrada.</p>
                  )}
                </div>
              </div>
              <div className="chat-message clearfix">
                <div className="input-group mb-0">
                  <input
                    type="text"
                    className="form-control rounded-start-pill"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Digite sua mensagem..."
                  />
                  <button className="btn btn-primary rounded-end-pill" onClick={sendMessage}>Enviar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagesPage;