import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const CourseList = () => {
    const [courses, setCourses] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('http://localhost/avalun/backend/courses/list.php')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erro na resposta da rede');
                }
                return response.json();
            })
            .then(data => {
                console.log('Dados recebidos:', data); // Log dos dados recebidos
                setCourses(data);
            })
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                setError('Erro ao buscar os dados');
            });
    }, []);

    return (
        <div className="container mt-5">
            <h1 className="mb-4">Cursos</h1>
            <div className="row g-4">
                {error ? (
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                ) : courses.length > 0 ? (
                    courses.map(course => (
                        <div className="col-md-4" key={course.id}>
                            <div className="card border-0 shadow-sm rounded-4 h-100">
                                <div className="card-body">
                                    <h5 className="card-title">{course.name}</h5>
                                    <h6 className="card-subtitle mb-2 text-muted">{course.code}</h6>
                                    <p className="card-text"><strong>Categoria:</strong> {course.category}</p>
                                    <p className="card-text"><strong>Visibilidade:</strong> {course.visibility}</p>
                                    <p className="card-text"><strong>Data de Início:</strong> {course.start_date}</p>
                                    <p className="card-text"><strong>Data de Término:</strong> {course.end_date}</p>
                                    <p className="card-text"><strong>Modalidade:</strong> {course.modality}</p>
                                    <p className="card-text"><strong>Resumo:</strong> {course.summary}</p>
                                    <p className="card-text"><strong>Formato:</strong> {course.format}</p>
                                    <p className="card-text"><strong>Seções:</strong> {course.sections}</p>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="col-12">
                        <div className="alert alert-info" role="alert">
                            Nenhum curso encontrado.
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CourseList;
