import React, { useState } from 'react';
import CourseForm from '../../../components/CourseForm';
import CourseInfos from '../../../components/CourseInfos';

const CourseNew = () => {
  const [formData, setFormData] = useState({});

  const handleFormChange = (updatedForm) => {
    setFormData(updatedForm);
  };
  
  return (
    <div className="container mt-5">
      <h1>Novo curso</h1>
      <div className="row g-4">
        <div className="col-md-8">
          <div className="card border-0 shadow-sm rounded-4">
            <div className="card-body">
              <CourseForm onFormChange={handleFormChange} />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card border-0 shadow-sm rounded-4 sticky-top">
            <div className="card-header rounded-top-4">
              <h5 className="mb-0">Informações do Curso:</h5>

            </div>
            <div className="card-body" id="CourseInfos">
            <CourseInfos formData={formData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseNew;
