import React from 'react';

const ProfileView = ({ user }) => {
  return (
    <div>
      <div className="card mb-4 p-3 border-0 rounded-4 shadow-sm">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <h6 className="mb-3">Informações Pessoais</h6>
              <table className="table user-view-table m-0">
                <tbody>
                  <tr>
                    <td>Nome:</td>
                    <td>{user.user_name}</td>
                  </tr>
                  <tr>
                    <td>Email:</td>
                    <td>{user.user_email}</td>
                  </tr>
                  <tr>
                    <td>Última Atividade:</td>
                    <td>{new Date(user.user_last_activity).toLocaleDateString()}</td>
                  </tr>
                  <tr>
                    <td>Função:</td>
                    <td>{user.user_role}</td>
                  </tr>
                  <tr>
                    <td>Situação:</td>
                    <td><span className={`badge ${user.user_status === 'active' ? 'badge-outline-success' : 'badge-outline-danger'}`}>{user.user_status}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <h6 className="mt-4 mb-3">Redes Sociais</h6>
              <table className="table user-view-table m-0">
                <tbody>
                  <tr>
                    <td><i className="bi bi-twitter me-2"></i>Twitter:</td>
                    <td><a href="https://twitter.com/user">https://twitter.com/user</a></td>
                  </tr>
                  <tr>
                    <td><i className="bi bi-facebook me-2"></i>Facebook:</td>
                    <td><a href="https://www.facebook.com/user">https://www.facebook.com/user</a></td>
                  </tr>
                  <tr>
                    <td><i className="bi bi-instagram me-2"></i>Instagram:</td>
                    <td><a href="https://www.instagram.com/user">https://www.instagram.com/user</a></td>
                  </tr>
                </tbody>
              </table>
              <h6 className="mt-4 mb-3">Contatos</h6>
              <table className="table user-view-table m-0">
                <tbody>
                  <tr>
                    <td>Telefone:</td>
                    <td>+0 (123) 456 7891</td>
                  </tr>
                  <tr>
                    <td>E-mail:</td>
                    <td><a href={`mailto:${user.user_email}`}>{user.user_email}</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <hr className="border-light m-0" />
      </div>
      <div className="card mb-4 border-0 rounded-4 shadow-sm">
        <div className="tab-content p-4">
          <div className="tab-pane active show" id="projects-tab" role="tabpanel">
            <div className="d-flex align-items-center">
              <div className="flex-1">
                <h4 className="card-title mb-4">Cursos</h4>
              </div>
            </div>
            <div>
              <p>Web Design</p>
              <div className="progress mb-3" style={{ height: '5px' }}>
                <div className="progress-bar bg-primary" role="progressbar" style={{ width: '80%' }} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <p>Website Markup</p>
              <div className="progress mb-3" style={{ height: '5px' }}>
                <div className="progress-bar bg-danger" role="progressbar" style={{ width: '72%' }} aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <p>One Page</p>
              <div className="progress mb-3" style={{ height: '5px' }}>
                <div className="progress-bar bg-success" role="progressbar" style={{ width: '89%' }} aria-valuenow="89" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <p>Mobile Template</p>
              <div className="progress mb-3" style={{ height: '5px' }}>
                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '55%' }} aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <p>Backend API</p>
              <div className="progress" style={{ height: '5px' }}>
                <div className="progress-bar bg-info" role="progressbar" style={{ width: '66%' }} aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileView;