import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';

const ParticipantAdd = ({ classID, onParticipantAdded }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [role, setRole] = useState('Estudante');

  useEffect(() => {
    // Carregar a lista de usuários para o Select2
    $.ajax({
      url: 'http://localhost/avalun/backend/get_users.php',
      method: 'GET',
      success: function (data) {
        setUsers(data);
        $('#userSelect').select2({ data: data.map(user => ({ id: user.user_id, text: user.user_name })) });
      },
    });
  }, []);

  const handleParticipantAdd = () => {
    $.ajax({
      url: 'http://localhost/avalun/backend/participant_add.php',
      method: 'POST',
      data: {
        class_id: classID,
        user_id: selectedUser,
        role: role,
      },
      success: function () {
        onParticipantAdded();
      },
    });
  };

  return (
    <div className="modal fade" id="addParticipantModal" tabIndex="-1" aria-labelledby="addParticipantModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addParticipantModalLabel">Adicionar Participante</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="userSelect" className="form-label">Selecionar Usuário</label>
              <select id="userSelect" className="form-select" onChange={e => setSelectedUser(e.target.value)}></select>
            </div>
            <div className="mb-3">
              <label htmlFor="roleSelect" className="form-label">Selecionar Papel</label>
              <select id="roleSelect" className="form-select" value={role} onChange={e => setRole(e.target.value)}>
                <option value="Visitante">Visitante</option>
                <option value="Estudante">Estudante</option>
                <option value="Assistente">Assistente</option>
                <option value="Instrutor">Instrutor(a)</option>
              </select>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
            <button type="button" className="btn btn-primary" onClick={handleParticipantAdd}>Adicionar</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipantAdd;