import React from 'react';
import { Link } from 'react-router-dom';

const Courses = () => {
  return (
    <>

          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <div>
                  <h1 className="mb-0">Cursos</h1>
                  <small className="text-muted">Gerêncie Cursos e Trumas:</small>
                </div>
              </div>
              <div className='painel__container'>
                <div>
                  <h2>Curso</h2>
                  <ul>
                    <li><Link to="/painel/cursos/novo">Cadastrar novo Curso</Link></li>
                    <li><Link to="/painel/cursos/listar">Listar Cursos</Link></li>
                    <li><Link to="/painel/cursos/categorias">Categorias de Cursos</Link></li>
                    <li><Link to="/painel/cursos/modalidades">Modalidades dos Cursos</Link></li>
                  </ul>
                </div>
                <hr/>
                <div>
                  <h2>Trumas</h2>
                  <ul>
                    <li><Link to="/painel/cursos/listar-turmas">Turmas dos Cursos</Link></li>
                    <li><Link to="./">Cadastrar nova Truma</Link></li>
                    <li><Link to="./">Listar Turmas</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
    </>


  );
};

export default Courses;

