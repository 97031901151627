import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PermissionsList = () => {
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        axios.get('http://localhost/avalun/backend/acconts/listPermissions.php')
            .then(response => setPermissions(response.data))
            .catch(error => console.error('Erro ao buscar os Permissoes:', error));
    }, []);

    return (
        <div>
            <div className="container mt-5">
                <h1>Cargos e Permissões do Sistema</h1>
                <div className="row g-4">
                    <div className="col-md-12">
                        <div className="card border-0 shadow-sm rounded-4">
                            <div className="card-body">
                                <ul>
                                    {permissions.map(permission => (
                                        <li key={permission.id}>
                                            {permission.name}
                                            <hr />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default PermissionsList;
