import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const MyCoursesPage = () => {
  const userID = localStorage.getItem('user_id');
  const [classes, setCourses] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`http://localhost/avalun/backend/courses/my.php?user_id=${userID}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro na resposta da rede');
        }
        return response.json();
      })
      .then(data => {
        if (data.message) {
          setError(data.message);
        } else {
          setCourses(data);
        }
      })
      .catch(error => {
        setError('Erro ao buscar os dados');
        console.error('Erro ao buscar os dados:', error);
      });
  }, []);

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div>
              <h1 className="mb-0">Meus Cursos</h1>
              <small className="text-muted">Confira e acesse as suas turmas:</small>
            </div>
            <nav className="nav align-items-center gap-2">
              <Link to="../painel/cursos/categoria/nova" className="btn btn-sm btn-default rounded-pill d-flex align-items-center">
                <i className="bi bi-plus-circle"></i>
                <span className="ms-2 lh-1 text-uppercase">Criar Categoria</span>
              </Link>
              <Link to="../painel/cursos/novo" className="btn btn-default rounded-pill d-flex align-items-center">
                <i className="bi bi-plus-circle"></i>
                <span className="ms-2 lh-1 text-uppercase">Criar Curso</span>
              </Link>
            </nav>
          </div>
          {error ? (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          ) : (
            <div className="row row-cols-1 row-cols-md-4 g-4">
              {classes.map(myclass => (
                <div className="col" key={myclass.id}>
                  <div className="card h-100 border-0 rounded-4 shadow-sm">
                    <img src={myclass.image_url || 'default_image_url.jpg'} className="card-img-top" alt={myclass.class_name} />
                    <div className="card-body">
                      <h5 className="card-title">{myclass.class_name}</h5>
                      <p className="card-text">{myclass.class_summary}</p>
                      <Link to={`/cursos/${myclass.class_id}`} className="btn btn-primary mt-3">
                        Ver Curso
                      </Link>
                    </div>
                    <div className="card-footer">
                      <small className="text-body-secondary">Último acesso: {myclass.uc_last_viewed ? new Date(myclass.uc_last_viewed).toLocaleString() : 'Nunca'}</small>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyCoursesPage;